import { useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useAPI,
  ChatMessage,
  ViewContext,
  Icon,
  LoaderChat,
  Feedback,
  Button,
} from "components/lib";
import Axios from "axios";
import settings from "settings";

// get the chat id from the settings file
const chat_id = settings[process.env.REACT_APP_ENV].chat_id;

//chat array to hold the chat
var chat = [];

//save the history id so we can update the history record
let currentHistoryId = null;

// the prompt object stores all the info about the current prompt
var promptObj = [];

export function ChatAi(props) {
  // allows us to navigate to a different page
  const navigate = useNavigate();

  // kinda like getElementById
  const scrollToRef = useRef(null);
  const loaderRef = useRef(null);

  // allows us to get props/functions from parent
  const context = useContext(ViewContext);

  //allows us to get URL params - in this case the prompt id
  const params = useParams();

  //set state for the prompt
  const [promptQuestionText, setPromptText] = useState([""]);

  //create a state for the chat so we can update the UI
  const [chatState, setChatState] = useState([]);

  //create a state for the history so we can update the UI
  const [chatHistory, setHistoryState] = useState([]);

  // temporary state for form submission status
  const [submitting, setSubmitting] = useState(false);
  const [notification, setNotification] = useState("");
  const [promptFormDefaultText, setPromptFormDefaultText] = useState(""); // this is the message that will be displayed in the prompt text area TODO: move this to a temporary answer???

  // tracks the open state of the history menu
  const [open, setOpen] = useState(false); // history is open

  // if there is not an id set it to the default chat id in the settings file
  if (params.id == null) params.id = chat_id;

  // get the prompt object from the API
  promptObj = useAPI(`/api/prompt/min/${params.id}`).data || []; // uses minimum API call (prompt.title, id, and the last user prompt.content)

  useEffect(() => {
    if (params.id != null)
      // get the history object from the API
      postGetHistory();

    // we want to reset this state when the prompt changes
    setChatState(null);
  }, [promptObj]);

  useEffect(() => {
    // If the history id is set, load the specific chat from the history
    if (params.history_id) {
      currentHistoryId = params.history_id;
      postLoadChatFromHistory(currentHistoryId);
    }
  }, [params.history_id]);

  // load the greeting message into the chat history
  useEffect(() => {
    if (promptObj.greeting && chatState == null)
      updateChat(promptObj.greeting, "assistant");
  }, [promptObj.greeting]);

  // reset the state when the component unmounts
  useEffect(() => {
    return () => {
      // Reset the state when the component unmounts
      reset();
    };
  }, []);

  // reset the state when the prompt id changes
  useEffect(() => {
    return () => {
      // Reset the state when the component unmounts
      reset();
    };
  }, [params.id]);

  function reset() {
    setChatState(null);
    setHistoryState(null);
    setPromptFormDefaultText(null);
    setPromptText(null);
    chat = [];
    currentHistoryId = null;
    promptObj = [];
  }

  // function to update the chat - keeping track of the whole chat
  function updateChat(text, role) {
    var tempChat = { role: role, content: text };
    chat.push(tempChat);
    setChatState(chat);
  }

  //add to the question input to update the state
  const handleChange = (event) => {
    setPromptText(event.target.value);
  };

  function handleSubmit(event) {
    // Prevent the browser from reloading the page
    event.preventDefault();

    if (submitting) return;

    const postUrl = "/api/ai/chat";
    let dataTemp = {};

    // set the prompt to the prompt text from the prompt text area
    // we are currently using the prompt value in the aiController to validate
    // we may want to change this to use the latest message insted [TBD]
    dataTemp.prompt = promptQuestionText;
    // need to grab the whole chat history and send it to the server
    dataTemp.messages = chat;
    //send the history id to the server so we can update the history record
    dataTemp.history_id = currentHistoryId;
    dataTemp.id = promptObj.id;

    // update the chat
    updateChat(dataTemp.prompt, "user");

    // clear the prompt text area
    setPromptText(null);

    // update thexprompt text area message
    //TODO not working
    setPromptFormDefaultText("Thinking...");

    // scroll to the bottom of the chat so it is visible for the user
    scrollToBottom();

    // post the prompt
    postPrompt(postUrl, dataTemp);
    setSubmitting(true);
    showLoading(true);
  }

  // post the prompt to the server and get the response
  async function postPrompt(postUrl, dataTemp) {
    await Axios({
      method: "post",
      url: postUrl,
      data: dataTemp,
    })
      .then((response) => {
        // update the chat with the response
        updateChat(response.data.answer, "assistant");
        // create the history record (only happens if it doesn't already exist)
        //make sure the message did not get flagged
        if (response?.data?.flagged !== true)
          postCreateHistory(dataTemp.prompt);
      })
      .catch((error) => {
        newNotfication("Error", error);
        context.handleError(error);
      });
    showLoading(false);
    setSubmitting(false);
    setPromptFormDefaultText("How can I help you?");
    scrollToBottom();
  }

  // get history
  // for this users history get any records that have this prompt_id
  async function postGetHistory() {
    await Axios({
      method: "post",
      url: `/api/history/prompt/${params.id}`,
    })
      .then((response) => {
        // display history
        // display all historys from server in order
        if (response?.data?.length > 0) {
          setHistoryState(response.data);
        }
      })
      .catch((error) => {
        newNotfication("Error", error);
        context.handleError(error);
      });
    return;
  }

  async function postCreateHistory(promptQuestionText) {
    //create the history record
    if (currentHistoryId != null) return;
    let tempData = {
      id: params.id,
      text: promptQuestionText,
      message: chat,
      prompt_title: promptObj.title,
    };
    await Axios({
      method: "post",
      url: `/api/history/new/${params.id}`,
      data: tempData,
    })
      .then((response) => {
        //update the history id
        currentHistoryId = response.data.history.id;
        // update the history with the response
        updateHistory(response.data.history.id, response.data.history.title);
      })
      .catch((error) => {
        context.handleError(error);
      });
  }

  async function postLoadChatFromHistory(historyId) {
    //set the history id to the selected history
    currentHistoryId = historyId;

    if (submitting) return;
    // clear the prompt text area
    setPromptText(null);
    // scroll to the bottom of the chat so it is visible for the user
    scrollToBottom();
    setSubmitting(true);

    await Axios({
      method: "post",
      url: `/api/history/${historyId}`,
    })
      .then((response) => {
        // update the chat with the response
        setChatState(response.data[0].message);
        chat = response.data[0].message;
      })
      .catch((error) => {
        newNotfication("Error", error);
        context.handleError(error);
      });

    setSubmitting(false);
    scrollToBottom();
    navigate(`/ai/Chat/${params.id}/${historyId}`);
  }

  //add a new history object into the list
  function updateHistory(id, title) {
    // check if the record id already exists in the chatHistory
    const existingHistoryRecord = chatHistory.some(
      (history) => history.id === id
    );
    // if there is no existing record with the given id, add it to the history
    if (!existingHistoryRecord) {
      const updatedChatHistory = [{ id: id, title: title }, ...chatHistory];
      setHistoryState(updatedChatHistory);
    }
  }

  //create a new prompt
  function createNew() {
    setChatState(null);
    setPromptText(null);
    chat = [];
    currentHistoryId = null;
    updateChat(promptObj.greeting, "assistant");
    navigate(`/ai/Chat/${promptObj.id}`);
  }

  function newNotfication(type, message) {
    setNotification({ type, message });
  }

  // submit the form when the user presses enter and new line with shift+enter
  function enterSubmit(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      handleSubmit(event);
    }
  }

  // after the answer loads we need to scroll to the bottom so the entire answer is visible
  function scrollToBottom() {
    setTimeout(() => {
      scrollToRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }, 200); // 200ms delay before scrolling
  }

  //show the loading animation
  function showLoading(boolVal) {
    const displayVal = boolVal ? "visible" : "hidden";
    loaderRef.current.style.visibility = displayVal;
  }
  return (
    <section className="chat-view-container">
      <div className="chat-text">
        <div id="promptContainer" className="top-div">
          <div className="inner-div">
            <header className="content-header scroller chatHeader">
              <Button
                className="viewAllHistoryBtn"
                action={(e) => navigate(`/history/${params.id}`)}
                icon="clock"
                text="History"
              ></Button>
              <h1>
                <span className="capitalize"> {promptObj.title}</span>
              </h1>
            </header>
            <div id="msg" ref={scrollToRef}>
              {chatState?.map((answer, index) => (
                <ChatMessage
                  row={index % 2 === 0 ? "altA" : "altB"}
                  key={index}
                  text={answer.content}
                  icon={answer.role === "assistant" ? "cpu" : "user"}
                  className={answer.role}
                />
              ))}

              <div className="loaderSmall" ref={loaderRef}>
                <LoaderChat />
              </div>
            </div>
          </div>
        </div>
        <div className="chat-input-container">
          <form id="questionForm" method="post" onSubmit={handleSubmit}>
            <div className="questionWrapper">
              <textarea
                id="question"
                type="text"
                name="question"
                required
                default={promptFormDefaultText || ""}
                onChange={handleChange}
                onKeyDown={enterSubmit}
                value={promptQuestionText || ""}
              ></textarea>
              <button type="submit">
                <Icon image="arrow-up" size="35" />
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="history history-container">
        <div className="inner-div">
          {chatHistory?.length > 0 ? (
            <>
              <h4>History</h4>
              <button className="new" onClick={createNew}>
                <Icon image="plus-circle" /> Create New
              </button>
            </>
          ) : null}
          {chatHistory?.slice(0, 15).map((history, index) => {
            const activeClass = history.id === currentHistoryId ? "active" : "";
            return (
              <button
                key={index}
                onClick={(e) => postLoadChatFromHistory(history.id)}
                className={activeClass}
              >
                {history.title}
              </button>
            );
          })}

          {chatHistory?.length > 15 ? (
            <button
              className="viewAll"
              onClick={(e) => navigate(`/history/${params.id}`)}
            >
              ...
            </button>
          ) : null}
        </div>
      </div>
      {/* TODO fit the feedback above submit <Feedback className="chatFeedback" /> */}
    </section>
  );
}

export function CompletionAi(props) {
  return (
    <div>
      <h1>Completion AI</h1>
      <p>
        Tyro_ Ai is a text based AI engine that uses a combination of machine
        learning and natural language processing to generate text.
      </p>
    </div>
  );
}
