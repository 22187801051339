/***
 *
 *   ERROR
 *   Form error message renders below input
 *
 **********/

import { ClassHelper } from "components/lib";
import Style from "./error.module.scss";

export function Error(props) {
  return "";
}
