/***
 *
 *   SIGN UP STEP 2
 *   Verify email address
 *   Step 1: create account
 *   Step 2: verify email address
 *   Step 3: select plan
 *
 **********/

import React, { useState } from "react";
import { Animate, Card, Row, Icon, Grid } from "components/lib";
import Style from "./noschool.module.scss";

export function SignupNoSchool(props) {
  return (
    <Animate type="pop">
      <Row className={Style.noSchoolCard}>
        <Card
          loading={false}
          restrictWidthWide
          center
          className={Style.container}
        >
          <Icon image="cloud-drizzle" size="60" className={Style.icon} />

          <h2 className="title">Sorry, no school found</h2>
          <p>
            Tyro.ai currently requires that all new accounts must be linked to a
            school within our system. To request access, please contact your
            school administrator.{" "}
          </p>
        </Card>
      </Row>
    </Animate>
  );
}
