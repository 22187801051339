import { Prompt } from "views/prompt/prompts";
import { Designer } from "views/prompt/designer";

const Routes = [
  {
    path: "/experts",
    view: Prompt,
    layout: "app",
    permission: "user",
    title: "Experts",
  },
  {
    path: "/experts/:topic",
    view: Prompt,
    layout: "app",
    permission: "user",
    title: "Experts",
  },

  {
    path: "/tools",
    view: Prompt,
    layout: "app",
    permission: "user",
    title: "Tools",
  },
  {
    path: "/tools/:topic",
    view: Prompt,
    layout: "app",
    permission: "user",
    title: "Tools",
  },
  {
    path: "/prompt/designer",
    view: Designer,
    layout: "app",
    permission: "supertyro",
    title: "Create a new prompt",
  },

  {
    path: "/prompt/designer/:id",
    view: Designer,
    layout: "app",
    permission: "supertyro",
    title: "Edit a prompt",
  },
];

export default Routes;
