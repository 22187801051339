/***
 *
 *   HISTORY
 *   View and edit your entire history
 *  - history is a list of all the prompts you've ever created
 * - custom view tyro
 *
 **********/

import React, { Fragment, useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  HistoryTable,
  ViewContext,
  useAPI,
  OrganizationCard,
} from "components/lib";

export function History(props) {
  //allows us to get URL params - in this case the prompt id
  const params = useParams();

  // set the url based on whether or not we have a prompt id
  var url = "";
  params.prompt_id
    ? (url = "/api/history/prompt/" + params.prompt_id)
    : (url = "/api/history");

  const data = useAPI(url);

  const context = useContext(ViewContext);
  const [history, setHistory] = useState([]);

  // so I can use button syntax instead of <Link> and update the URL
  const navigate = useNavigate();

  useEffect(() => {
    // format the history list
    let list = [];
    if (data?.data?.length) {
      list = data.data.map((x) => {
        const dateStr = x.updated_at;
        const date = new Date(dateStr);
        const formattedDate = date.toLocaleString("en-US", {
          dateStyle: "long",
          timeStyle: "short",
        });

        if (window.innerWidth < 768) {
          return {
            id: x.id,
            prompt_id: x.prompt_id,
            name: x.title,
          };
        } else {
          return {
            id: x.id,
            prompt_id: x.prompt_id,
            name: x.title,
            prompt: x.prompt_title,
            updated: formattedDate,
          };
        }
      });
      setHistory(list);
    }
  }, [data]);

  function deleteHistory(data) {
    context.modal.show(
      {
        title: "Delete History",
        form: {},
        buttonText: "Delete",
        text: `Are you sure you want to delete ${data.name}?`,
        url: `/api/history/${data.id}`,
        method: "DELETE",
        destructive: true,
      },
      () => {
        context.notification.show(data.name + " was deleted", "success", true);
      }
    );
  }

  function navigateToHistory(data) {
    navigate("/ai/Chat/" + data.prompt_id + "/" + data.id);
  }

  return (
    <>
      <header>
        <h1>
          <span>History</span>
        </h1>
      </header>
      <OrganizationCard>
        <HistoryTable
          search
          data={history}
          loading={history.loading}
          actions={{ delete: deleteHistory }}
          hide={["id", "prompt_id"]}
        />
      </OrganizationCard>
    </>
  );
}
