/***
 *
 *   History Page
 *   view a specific history that belong to a user
 *
 **********/

import { useParams } from "react-router-dom";

import { OrganizationCard, Animate, useAPI, Header } from "components/lib";

export function History(props) {
  const params = useParams();

  //get the student

  const history = useAPI("/api/history/view/" + params.id) || [];
  const date = new Date(history?.data?.updated_at);

  return (
    <>
      <Header />

      <header className="content-header">
        <h1>
          Classroom: <span>{history?.data?.title}</span>
        </h1>
      </header>

      <Animate>
        <OrganizationCard
          loading={history.loading}
          title={history?.data?.title}
        >
          <b>Prompt: </b>
          {history?.data?.prompt_title}
          <br />
          <b>Last Update: </b>
          {date.toLocaleString()}
          <br />
        </OrganizationCard>
        <OrganizationCard loading={history.loading} title="Conversation">
          {history?.data?.message?.map((msg, index) => {
            return (
              <div key={index}>
                <b>Message: </b>
                {msg.role}
                <br />
                {msg.content}
                <br />
                <br />
              </div>
            );
          })}
        </OrganizationCard>
      </Animate>
    </>
  );
}
