/***
 *
 *   BLUR
 *   use to blur the page

 **********/

import Style from "./blur.module.scss";

export function Blur() {
  return (
    <div id="blur-overlay" className={Style.blurBg}>
      {" "}
    </div>
  );
}
