import { useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Icon, LoaderChat, ViewContext, FileUpload } from "components/lib";
import Axios from "axios";
import settings from "settings";

export function ImageAi(props) {
  // form state
  const [questionInput, setQuestionInput] = useState(props.question || "");
  const [selectedFile, setSelectedFile] = useState(null);

  //set state for the image response
  const [imageUrls, setImageUrls] = useState([]);

  // temporary state for form submission status
  const [submitting, setSubmitting] = useState(false);

  const [notification, setNotification] = useState("");

  // allows us to get props/functions from parent
  const context = useContext(ViewContext);

  // kinda like getElementById
  const loaderRef = useRef(null);

  //add to the question input to update the state
  const handleTextAreaChange = (event) => {
    setQuestionInput(event.target.value);
  };

  //add to the question input to update the state
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  async function handleSubmit(event) {
    // Prevent the browser from reloading the page
    event.preventDefault();

    console.log("values", questionInput, selectedFile);

    if (submitting) return;

    // clear the prompt text area
    setSubmitting(true);
    showLoading(true);

    const formData = new FormData();
    formData.append("prompt", questionInput);
    formData.append("img", selectedFile);

    // post the prompt

    await Axios({
      method: "post",
      url: "/api/ai/image",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log(response.data);
        const urls = response.data.data.map((item) => item.url);
        setImageUrls(urls);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
        } else {
          console.log(error.message);
        }
        newNotfication("Error", error);
        context.handleError(error);
      });

    showLoading(false);
    setSubmitting(false);
  }

  // submit the form when the user presses enter and new line with shift+enter
  function enterSubmit(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      handleSubmit(event);
    }
  }

  function showLoading(boolVal) {
    const displayVal = boolVal ? "visible" : "hidden";
    loaderRef.current.style.visibility = displayVal;
  }

  function newNotfication(type, message) {
    setNotification({ type, message });
  }

  return (
    <section className="chat-view-container">
      <div className="chat-text">
        <div id="promptContainer" className="top-div">
          <div className="inner-div">
            <header className="content-header scroller chatHeader">
              <h1>
                <span className="capitalize"> Image</span>
              </h1>
            </header>
            <div id="msg">
              {imageUrls.map((url, index) => (
                <img key={index} src={url} alt={`Description ${index}`} />
              ))}

              <div className="loaderSmall" ref={loaderRef}>
                <LoaderChat />
              </div>
            </div>
          </div>
        </div>
        <div className="chat-input-container">
          <form id="questionForm" method="post" onSubmit={handleSubmit}>
            <div className="questionWrapper">
              <textarea
                id="question"
                type="text"
                name="question"
                required
                onChange={handleTextAreaChange}
                onKeyDown={enterSubmit}
                value={questionInput}
              ></textarea>
              <input
                type="file"
                id="img"
                name="img"
                accept="image/png, image/jpeg"
                onChange={handleFileChange}
              />
              <button type="submit">
                submit
                <br />
                <Icon image="corner-down-right" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
