/***
 *
 *   LOADER
 *   Infinite blinking animation for loading states
 *
 **********/

import { ClassHelper, Logo } from "components/lib";
import Style from "./loader.module.scss";

export function Loader(props) {
  const loaderStyle = ClassHelper(Style, props);

  return (
    <div className={loaderStyle}>
      <Logo className="tyroLoader" />
    </div>
  );
}
