import React, { useState } from "react";
import { Button, ClassHelper } from "components/lib";

import Style from "./favoriteButton.module.scss";

export const FavoriteButton = ({ prompt_id, toggleFavorite, favorited }) => {
  const [isClicked, setIsClicked] = useState(favorited);

  return (
    <Button
      icon="star"
      iconColor="white"
      size="23"
      className={`${Style.base} ${
        isClicked ? Style.favorited : Style.favorite
      }`}
      action={() => {
        toggleFavorite(prompt_id);
        setIsClicked(!isClicked);
      }}
    />
  );
};
