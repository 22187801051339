/***
 *
 *   ACCOUNT Page
 *   Manage an account's details
 *
 **********/

import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  AuthContext,
  ViewContext,
  OrganizationCard,
  Animate,
  Button,
  useAPI,
  Grid,
  useNavigate,
  OrganizationTable,
  Header,
  usePermissions,
} from "components/lib";

import axios from "axios";

export function Manage(props) {
  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const permissions = usePermissions();

  const params = useParams();
  const account_id = authContext.user.account_id;

  // get the current account data
  const account = useAPI("/api/account/") || [];

  // get the current account settings
  let accountSetting = useAPI("/api/account/" + account_id + "/settings");
  const [info, setInfo] = useState({});

  //get the account admin admins
  const data = useAPI("/api/account/getAdminUsers/" + account_id) || [];
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    if (accountSetting?.data) {
      setInfo(accountSetting.data);
    }
  }, [accountSetting]);

  useEffect(() => {
    // format the user list
    let list = [];

    if (data?.data?.admin?.length) {
      data.data.admin.forEach((x) => {
        list.push({
          id: x.id,
          name: x.name,
          email: x.email,
          permission: x.permission,
          status: x.verified ? "Verified" : "Registered",
        });
      });
    }

    if (data?.data?.invites?.length) {
      data.data.invites.forEach((x) => {
        list.push({
          id: x.id,
          name: "",
          email: x.email,
          permission: x.permission || "user",
          status: "Invited",
        });
      });
    }

    setAdmins(list);
  }, [data]);

  function editUser(data, callback) {
    viewContext.modal.show(
      {
        title: "Update User",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          email: {
            label: "Email",
            type: "email",
            value: data.email,
            required: true,
          },
        },
        buttonText: "Save",
        url: "/api/user",
        method: "PATCH",
      },
      (res) => {
        viewContext.notification.show(
          data.name + " was updated",
          "success",
          true
        );
        callback(res);
      }
    );
  }

  function deleteUser(data, callback) {
    viewContext.modal.show(
      {
        title: "Delete User",
        form: {},
        buttonText: "Delete User",
        text: `Are you sure you want to delete ${data.name}?`,
        url: `/api/user/${data.id}`,
        method: "DELETE",
        destructive: true,
      },
      () => {
        viewContext.notification.show(
          data.name + " was deleted",
          "success",
          true
        );
        callback();
      }
    );
  }

  function deleteInvite(data) {
    viewContext.modal.show(
      {
        title: "Delete Invite",
        form: {},
        buttonText: "Delete Invite",
        text: `Are you sure you want to delete the invite for ${data.email}?`,
        url: `/api/invite/${data.id}`,
        method: "DELETE",
        destructive: true,
      },
      () => {
        viewContext.notification.show(
          `${data.email}'s invite was deleted`,
          "success",
          true
        );

        // remove from state
        const s = [...admins];
        s.splice(
          s.findIndex((u) => u.id === data.id),
          1
        );
        setAdmins(s);
      }
    );
  }

  async function resendInvite(data) {
    try {
      await axios({
        url: "/api/invite",
        method: "post",
        data: { email: data.email },
      });

      viewContext.notification.show(
        `Invite re-sent to ${data.email}`,
        "success",
        true
      );
    } catch (err) {
      viewContext.handleError(err);
    }
  }

  // attach the per row actions for invites
  if (admins.length) {
    admins.forEach((u) => {
      if (u.status === "Invited") {
        u.actions = {
          invite: resendInvite,
          delete: deleteInvite,
        };
      } else if (u.status === "Invited...") {
        u.actions = {};
      } else if (u.permission === "owner") {
        u.actions = {};
      }
    });
  }

  function editAccountInfo() {
    viewContext.modal.show(
      {
        title: "Edit Account Info",
        buttonText: "Save",
        url: "/api/account/settings",
        method: "PATCH",
        form: {
          id: {
            type: "hidden",
            value: account.data?.id,
          },
          name: {
            label: "Name",
            type: "text",
            required: true,
            value: info?.name,
          },
          contact_name: {
            label: "Contact name",
            type: "text",
            required: false,
            value: info?.contact_name,
          },
          contact_title: {
            label: "Contact title",
            required: false,
            value: info?.contact_title,
          },
          contact_email: {
            label: "Contact email",
            type: "text",
            required: false,
            value: info?.contact_email,
          },
          contact_phone: {
            label: "Contact phone",
            type: "text",
            required: false,
            value: info?.contact_phone,
          },
          address: {
            label: "Address",
            type: "textarea",
            required: false,
            value: info?.address,
          },
        },
      },
      (form) => {
        let formatedData = {};
        formatedData.id = form.id.value;
        formatedData.name = form.name.value;
        formatedData.contact_title = form.contact_title.value;
        formatedData.contact_name = form.contact_name.value;
        formatedData.contact_email = form.contact_email.value;
        formatedData.contact_title = form.contact_title.value;
        formatedData.contact_phone = form.contact_phone.value;
        formatedData.address = form.address.value;

        // Update the state
        setInfo(formatedData);
      }
    );
  }

  function inviteAdmin() {
    viewContext.modal.show(
      {
        title: "Add Admin",
        buttonText: "Send Invite",
        url: "/api/invite",
        method: "POST",
        form: {
          email: {
            label: "Email",
            type: "text",
            required: true,
          },
          permission: {
            value: "account_admin",
            type: "hidden",
          },
          account_id: {
            value: account_id,
            type: "hidden",
          },
        },
      },
      (form) => {
        // remove from state
        const s = [...admins];
        s.push({
          name: "",
          email: form.email.value,
          permission: "account_admin",
          status: "Invited...",
        });
        setAdmins(s);
      }
    );
  }

  function friendlyPermissionName(str) {
    if (!str) return;
    // Split the string into an array on underscore
    let strArr = str.split("_");

    // Map over the array and capitalize each word
    let capitalizedArr = strArr.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // join the words back into a single string with no underscore
    let result = capitalizedArr.join(" ");

    return result;
  }

  return (
    <>
      <Header />

      <Animate>
        <OrganizationCard loading={account.loading} title="Account Details">
          <Grid cols={2}>
            <div>
              <b>Name:</b> {account.data?.name}
              <br />
              <b>Active:</b> {account.data?.active ? "true" : "false"}
              <br />
              <b>Created:</b>{" "}
              {new Date(account.data?.date_created).toLocaleDateString()}
              <br />
              <br />
              <Button
                className="rounded big"
                text="Edit account info"
                color="green"
                action={editAccountInfo}
                size="20"
              />
            </div>
            <div>
              <b>Primary Contact</b>
              <br />
              Name: {info?.contact_name}
              <br />
              Title: {info?.contact_title}
              <br />
              Email: {info?.contact_email}
              <br />
              Phone: {info?.contact_phone}
              <br />
              <div style={{ whiteSpace: "pre-wrap" }}>
                Address: {info?.address}
              </div>
            </div>
          </Grid>
        </OrganizationCard>
        <OrganizationCard title="Account Admins">
          <Button
            className="rounded big"
            text="Invite account admin"
            color="green"
            action={inviteAdmin}
            size="20"
          />
          <br />
          <br />
          <OrganizationTable
            data={admins}
            loading={data.loading}
            show={["email", "name", "permission", "status"]}
            badge={{
              col: "status",
              color: "blue",
              condition: [
                { value: "verified", color: "green" },
                { value: "registered", color: "white" },
                { value: "invited", color: "orange" },
                { value: "invited...", color: "orange" },
              ],
            }}
            actions={{
              edit: editUser,
              delete: deleteUser,
            }}
          />
        </OrganizationCard>
      </Animate>
    </>
  );
}
