/***
 *
 *   Advanced Chat component
 *   Wrapper component to add advanced elements to a chat prompt
 *
 *
 **********/

import { useState, useEffect, useContext } from "react";
import { Form, useAPI, ViewContext } from "components/lib";
import axios from "axios";

export function PromptThumbnail(props) {
  const context = useContext(ViewContext);
  //set state for the image response
  const [thumbnail, setThumbnail] = useState({});
  const [loading, setLoading] = useState(true);

  const componentData = useAPI(
    props.id ? `/api/prompt/component/thumbnail/${props.id}` : null
  );

  function thumbnailCallback(res) {
    setThumbnail({ url: res.data.url, prompt: res.data.text });
  }

  async function onRemove() {
    console.log("Goodbye");
    if (props.id) {
      await axios.delete(`/api/prompt/component/thumbnail/${props.id}`);
    }
    context.snack.show("Thumbnail removed", "success");
    props.onRemove();
  }

  useEffect(() => {
    if (componentData && !componentData.loading) {
      setThumbnail({
        url: componentData?.data?.thumbnail_url,
        prompt: componentData?.data?.thumbnail_prompt,
      });
    } else if (componentData === null) {
      setLoading(false);
    }
  }, [componentData]); // empty dependency array causes the effect to only run on mount and clean up on unmount

  return (
    <>
      <div className="thumbnailContainer">
        <img src={thumbnail.url} />
        {thumbnail.prompt}
      </div>
      {!componentData.loading && (
        <Form
          inputs={{
            prompt_id: {
              type: "hidden",
              value: props.id,
            },

            prompt: {
              type: "text",
              label: "Thumbnail Prompt",
              required: true,
              value: thumbnail.prompt,
            },

            size: {
              label: "Size",
              type: "radio",
              options: ["wide", "tall", "square"],
              default: "square",
              required: true,
            },

            visual: {
              label: "Style",
              type: "radio",
              options: ["natural", "vivid"],
              default: "natural",
              required: true,
            },

            hd: {
              label: "HD",
              type: "switch",
              value: "",
            },
          }}
          method="POST"
          url="/api/ai/thumbnail"
          buttonText="Create Thumbnail"
          callback={(res) => {
            thumbnailCallback(res);
          }}
        />
      )}

      {props.onRemove && (
        <button onClick={onRemove} className="removeBtn">
          Remove
        </button>
      )}
    </>
  );
}
