/***
 *
 *   AUTH LAYOUT
 *   Layout for the signup/signin pages
 *
 **********/

import { AuthNav } from "components/lib";
import { useLocation } from "react-router-dom";
import Style from "./auth.module.scss";

export function AuthLayout(props) {
  // we need to grab the pathname to get the current page
  // we use this to decide if we need to show the history button
  const location = useLocation();
  const currentPageName = location.pathname.split("/")[1]; // Get the first part of the pathname  console.log("currentPageName: ", currentPageName);

  return (
    <main className={`${Style.auth} ${currentPageName}`}>
      {<props.children {...props.data} />}
      {/* FAL remove for now <Footer /> */}
    </main>
  );
}
