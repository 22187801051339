import { ChatAi, CompletionAi } from "views/ai/text";
import { ImageAi } from "views/ai/image";

const Routes = [
  {
    path: "/ai/chat/",
    view: ChatAi,
    layout: "app",
    permission: "user",
    title: "Tyro_ chat",
  },

  {
    path: "/ai/chat/:id",
    view: ChatAi,
    layout: "app",
    permission: "user",
    title: "Tyro chat",
  },

  {
    path: "/ai/chat/:id/:history_id",
    view: ChatAi,
    layout: "app",
    permission: "user",
    title: "Tyro chat",
  },

  {
    path: "/ai/completion/:id",
    view: CompletionAi,
    layout: "app",
    permission: "user",
    title: "Tyro text",
  },

  {
    path: "/ai/image/",
    view: ImageAi,
    layout: "app",
    permission: "user",
    title: "Tyro image",
  },
];

export default Routes;
