/*
 * CONTENT page
 * View to allow the admin to magage the organization's content
 *
 */

import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";

import {
  OrganizationCard,
  ViewContext,
  Header,
  Switch,
  useAPI,
  Search,
  Animate,
  Grid,
  SmallCard,
} from "components/lib";

let prompts = [];
let topics = require("../../app/topics").topics;

export function Content(props) {
  const params = useParams();
  const context = useContext(ViewContext);

  // get organization
  //const organization = useAPI("/api/account/district") || [];

  //State
  const [isManaged, setManaged] = useState();
  const [isLoading, setLoading] = useState(true);
  const [promptState, setPrompts] = useState([]);

  //keeps track of the topic that is active
  const [topicState, setTopic] = useState(params.topic || "all");

  // holds the prompts that are filtered
  const [promptFilterState, setPromptFilter] = useState([]);

  // we make some adjustment to the page if this is a school
  let isSchool = false;

  // get the organization settings
  let url = "/api/organization/settings/get";
  if (params.id) {
    // this is a school change the url
    // and set school to true
    url = "/api/organization/settings/" + params.id;
    isSchool = true;
  }
  const organization = useAPI(url);

  useEffect(() => {
    if (!organization.loading && organization?.data) {
      setManaged(organization.data.manage_content);
    }
  }, [organization]);

  useEffect(() => {
    const fetchData = async () => {
      setManaged(organization?.data?.manage_content);
      if (!organization?.data?.manage_content || promptState.data) return;
      try {
        // Get the prompts
        const response = await Axios.get("/api/prompt-all");
        // Save the data to the state
        prompts = response.data;
      } catch (error) {
        console.error(`Failed to fetch data: ${error}`);
      }

      setPrompts(prompts);
      setPromptFilter(prompts);
      setLoading(false);
    };

    if (!organization.loading && organization?.data) {
      fetchData();
    }
  }, [isManaged]);

  // get the list of prompts that are allowed/dissallowed for this organization
  // and add the allowed property to the prompt state
  useEffect(() => {
    if (promptFilterState?.data) {
      const fetchData = async () => {
        const response = await Axios({
          url: "/api/organization/prompts/get/" + organization?.data?.id,
          method: "GET",
        });

        // create an array of prompts from the response
        let newData = response?.data.data;

        console.log("newData", newData);
        console.log("promptFilterState", promptFilterState);

        // Get the existing data
        let existingData = promptFilterState.data;
        // Enrich existing data items with allowed property from newData if their IDs match
        const enrichedExistingData = existingData.map((item) => {
          let matchingNewItem = newData.find(
            (newItem) => newItem.prompt_id === item.id
          );
          if (matchingNewItem) {
            console.log("matchingNewItem", matchingNewItem);
            return {
              ...item,
              allowed: matchingNewItem.allowed,
              is_district_managed: matchingNewItem.is_district_managed,
            };
          }

          // If no matching item was found in newData, return item as is
          return {
            ...item,
            allowed: true,
          };
        });

        console.log("enrichedExistingData", enrichedExistingData);

        setPromptFilter({ ...promptFilterState, data: enrichedExistingData });
        setPrompts({ ...promptState, data: enrichedExistingData });
      };

      fetchData();
    }
  }, [prompts]);

  async function toggleManaged() {
    // update the database
    await Axios({
      method: "post",
      url: "/api/organization/settings/update",
      data: {
        org_id: organization?.data?.id,
        data: { manage_content: !isManaged },
      },
    })
      .then((response) => {
        const message = isManaged ? "off" : "on";
        context.notification.show(
          "Managed content " + message,
          "success",
          true,
          "toast",
          "check"
        );
      })
      .catch((error) => {
        context.handleError("An error occurred. Please try again later.");
      });
    //update state
    organization.data.manage_content = !isManaged;
    setManaged(!isManaged);
  }

  async function togglePrompt(prompt_id) {
    // we need to get the prompt from the prompt state and check if it is_district_managed or not
    const unmodified_prompt = promptFilterState.data.find(
      (item) => item.id === prompt_id
    );

    //make sure we can make changes i.e. not district maganged
    if (unmodified_prompt.is_district_managed && !unmodified_prompt.allowed) {
      context.handleError(
        "This content is not allowed by the district. Please contact your district administrator to request access."
      );
      return;
    }

    //create updated array of prompts
    const updatedPrompts = promptFilterState.data.map((item) =>
      item.id === prompt_id ? { ...item, allowed: !item.allowed } : item
    );

    // update the state with new array
    setPromptFilter({ ...promptFilterState, data: updatedPrompts });

    // get the allowed value
    const { allowed } =
      updatedPrompts.find((item) => item.id === prompt_id) || {};

    const message = (allowed ? "Added" : "Removed") + " prompt";
    // update the database
    await Axios({
      method: "post",
      url: "/api/organization/prompt/set",
      data: {
        organization_id: organization?.data?.id,
        prompt_id: prompt_id,
        allowed: allowed, // update to the new value
      },
    })
      .then((response) => {
        context.notification.show(message, "success", true, "toast", "check");
      })
      .catch((error) => {
        context.handleError("An error occurred. Please try again later.");
      });
  }

  function search(term) {
    // search  descriptiona and title in each prompt
    // update state to show only filtered prompts

    let promptsToShow = [];

    promptState?.data.forEach((newPrompts) => {
      if (
        newPrompts.description
          .toString()
          .toLowerCase()
          .includes(term.toLowerCase()) ||
        newPrompts.title.toString().toLowerCase().includes(term.toLowerCase())
      ) {
        if (!promptsToShow.includes(newPrompts)) promptsToShow.push(newPrompts);
      }
    });
    setPromptFilter({ data: promptsToShow });
  }

  return (
    <>
      <Header />

      <header className="content-header">
        <h1>
          Manage Content: <span>{organization?.data?.name}</span>
        </h1>
      </header>

      <OrganizationCard
        loading={organization.loading}
        title={isSchool ? "School Setting" : "District Setting"}
      >
        The 'Content Management' feature gives you full control over the A.I.
        tools available to users in your organization.
        {!isSchool &&
          " In addition, school administrators can further manage the content available to their school."}
        {isSchool &&
          " In addition, district administrators have the ability to manage the content accessible to the entire district. If you're unable to access something, we recommend checking with your district administrator to confirm its availability for your school."}
        <Switch
          onChange={toggleManaged}
          label="Manage Content"
          default={organization.data?.manage_content}
        />
      </OrganizationCard>
      <div className={isManaged ? "visable" : "hidden"}>
        <OrganizationCard title={"System Content"}>
          <header className="content-topic promptHeader">
            <Search callback={search} placeholder="Search Prompts" />

            <div>
              {topics?.map((topic) => {
                //make sure to hide everythign until we have all the data
                if (isLoading) return false;

                // we need to count the number of prompts for each topic
                // and use that to hide the titles
                topic.count = 0; // Reset the count
                promptFilterState?.data?.map((prompt) => {
                  if (prompt.topics && prompt.topics.includes(topic.value)) {
                    topic.count += 1; // Increment the count if the topic is included in the prompt
                  }
                });

                const activeClass = topic.value === topicState ? "active" : "";

                if (
                  (topic.count < 1 && topic.value != "all") ||
                  !promptFilterState?.data?.length
                )
                  return false;

                return (
                  <button
                    key={topic.value}
                    className={`topicBtn  ${activeClass}`}
                    onClick={() => setTopic(topic.value)}
                  >
                    {topic.label}
                  </button>
                );
              })}
            </div>
            <br />
          </header>
          <Animate timeout={500}>
            {!promptFilterState?.data?.length && !isLoading
              ? "Sorry, there are no results matching your search."
              : null}

            {topics?.map((topic) => {
              if (topicState !== "all" && topicState !== topic.value)
                return false;
              if (topic.value === "all") return false;

              if (topic.count == 0 || !topic.count) return false;
              if (isLoading) return false;

              return (
                <div className="promptList" key={topic.label}>
                  <h2>{topic.label}</h2>

                  <Grid cols="4" shadow>
                    {promptFilterState?.data?.map((prompt) => {
                      if (!prompt.topics?.includes(topic.value)) return false;

                      return (
                        <div
                          onClick={() => togglePrompt(prompt.id)}
                          className={prompt.allowed ? "allowed" : "notAllowed"}
                          key={prompt.id}
                        >
                          <SmallCard
                            title={prompt.title}
                            restrictWidth
                            className="promptCard"
                          >
                            {prompt.is_district_managed && !prompt.allowed && (
                              <div className="lock" size={20}></div>
                            )}
                            <p className="promptMsg">{prompt.description}</p>
                          </SmallCard>
                        </div>
                      );
                    })}
                  </Grid>
                </div>
              );
            })}
          </Animate>
        </OrganizationCard>
      </div>
    </>
  );
}
