import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import {
  SmallCard,
  Loader,
  Animate,
  Grid,
  Feedback,
  AuthContext,
  FavoriteButton,
  Search,
  Button,
  Share,
} from "components/lib";

let favorites = [];
let prompts = [];
let topics = require("../../app/topics").topics;
let shareURL = "";
let shareTitle = "";

export function Prompt() {
  //allows us to get infomration about the user
  // in this case we want to find if the user is part of the
  //tyro account and show tyro prompts to them
  const authContext = useContext(AuthContext);
  const accountName = authContext.user?.accounts?.find(
    (x) => x.id === authContext.user?.account_id
  )?.name;

  // determine what type of ai we are using
  const path = window.location.pathname;
  const aiType = path.split("/")[1];

  //allows us to get URL params - in this case the prompt id
  const params = useParams();

  // used to update the URL
  const navigate = useNavigate();

  //state
  const [favoriteState, setFavorite] = useState();
  const [isLoading, setLoading] = useState(true);
  const [promptState, setPrompts] = useState();
  const [topicState, setTopic] = useState(params.topic || "all");
  const [promptFilterState, setPromptFilter] = useState([]);
  const [showShare, setShowShare] = useState(false);

  //FAL not using
  const [teacherState, setTeacherState] = useState(true);
  const [studentState, setStudentState] = useState(true);
  const [showStudentPrompt, setShowStudentPrompt] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Request for initial favorites data
        const response = await axios.get("/api/favorite");
        //save response to state
        favorites = response.data;
        // Once the first request is complete, we get the prompt data
        // first request to make a second request
        // add the school_id to the request
        let url = `/api/prompt-filtered/${aiType}/${authContext.user.school_source_id}`;

        if (accountName === "Tyro") {
          url = `/api/prompt-raw/${aiType}`;
        }
        const response2 = await axios.get(url);

        console.log(url, response);

        // Save the data to the state
        prompts = response2.data;
      } catch (error) {
        // console.error(`Failed to fetch data: ${error}`);
      }

      setPrompts(prompts);
      setPromptFilter(prompts);
      setFavorite(favorites);
      addRemoveFavoritesFromPrompts();
      setLoading(false);
    };
    fetchData();

    return () => {
      // This code runs when the component is unmounted
      setPrompts(null);
      setFavorite(null);
      setPromptFilter(null);
    };
  }, [path]);

  useEffect(() => {
    navigate(`/${aiType}/` + topicState);
  }, [topicState]);

  const handleChange = (event) => {
    const { name, checked } = event.target;

    if (name === "teacher") {
      setTeacherState(checked);
    } else if (name === "student") {
      setStudentState(checked);
    }
  };

  function handleToggle() {
    setShowStudentPrompt(!showStudentPrompt);
  }

  async function toggleFavorite(id) {
    await axios
      .post(`/api/favorite/${id}`)
      .then((response) => {
        favorites = response.data;
      })
      .catch((error) => {});
    addRemoveFavoritesFromPrompts();
  }

  // we need to inject or remove the favorites topic from prompts so that our page will add
  // the favorites section properly
  function addRemoveFavoritesFromPrompts() {
    // Create a new reference to the prompts object
    let tempPrompts = prompts;

    // Loop over each object in favorites' data array
    favorites.data?.map((favorite) => {
      // Find the index of this favorite in prompts
      const promptIndex = tempPrompts.data?.findIndex(
        (p) => p.id === favorite.prompt_id // check each prompt if their id matches with favorite prompt_id
      );

      // If the favorite was found in prompts
      if (promptIndex >= 0) {
        // If this prompt doesn't already have "favorites" in its topics
        if (!tempPrompts.data[promptIndex].topics.includes("favorites")) {
          // Add "favorites" to the topics of this prompt
          tempPrompts.data[promptIndex].topics.push("favorites");
        } else {
          // If this prompt already has 'favorites' in its topics, then filter it out
          tempPrompts.data[promptIndex].topics = tempPrompts.data[
            promptIndex
          ].topics.filter((topic) => topic !== "favorites");
        }
      }
    });

    // Update the state with the new prompts
    prompts = tempPrompts;
    setPrompts(prompts);
  }

  function search(term) {
    // search  descriptiona and title in each prompt
    // update state to show only filtered prompts

    let promptsToShow = [];

    promptState?.data.forEach((newPrompts) => {
      if (
        newPrompts.description
          .toString()
          .toLowerCase()
          .includes(term.toLowerCase()) ||
        newPrompts.title.toString().toLowerCase().includes(term.toLowerCase())
      ) {
        if (!promptsToShow.includes(newPrompts)) promptsToShow.push(newPrompts);
      }
    });
    setPromptFilter({ data: promptsToShow });
  }

  function share(url, title) {
    shareTitle = title;
    shareURL = url;
    setShowShare(true);
  }
  function hideShare() {
    setShowShare(false);
  }

  return (
    <>
      {isLoading && <Loader />}

      <header className="content-topic promptHeader">
        <Search callback={search} placeholder={`Search ${aiType}`} />
        <div className="topics">
          {/* Not using the page title for now
          <h1>
            Topic:<span className="capitalize"> {topicState}</span>
          </h1>
          */}

          {topics?.map((topic) => {
            //make sure to hide everythign until we have all the data
            if (isLoading) return false;

            // we need to count the number of prompts for each topic
            // and use that to hide the titles
            topic.count = 0; // Reset the count
            promptFilterState?.data?.map((prompt) => {
              if (prompt.topics && prompt.topics.includes(topic.value)) {
                if (
                  !showStudentPrompt &&
                  prompt.usertypes?.includes("Student") &&
                  !prompt.usertypes?.includes("Teacher") &&
                  !prompt.usertypes?.includes("Admin")
                )
                  return false;
                topic.count += 1; // Increment the count if the topic is included in the prompt
              }
            });

            const activeClass = topic.value === topicState ? "active" : "";

            if (
              (topic.count < 1 && topic.value != "all") ||
              !promptFilterState?.data?.length
            )
              return false;

            return (
              <button
                key={topic.value}
                className={`topicBtn  ${activeClass}`}
                onClick={() => setTopic(topic.value)}
              >
                {topic.label}
              </button>
            );
          })}

          <div id="filters">
            {/*  // not using the filters for now
            <Switch
              onChange={handleToggle}
              label="Only show teacher prompts?"
            />
            */}
          </div>
        </div>
        <br />
      </header>

      <Animate timeout={500}>
        {!promptFilterState?.data?.length && !isLoading
          ? "Sorry, there are no results matching your search."
          : null}

        {topics?.map((topic) => {
          if (topicState !== "all" && topicState !== topic.value) return false;
          if (topic.value === "all") return false;

          if (topic.count == 0 || !topic.count) return false;
          if (isLoading) return false;

          return (
            <div className="promptList" key={topic.label}>
              <h2>{topic.label}</h2>

              <Grid cols="4" shadow>
                {promptFilterState?.data?.map((prompt) => {
                  if (!prompt.topics?.includes(topic.value)) return false;

                  if (
                    !showStudentPrompt &&
                    prompt.usertypes?.includes("Student") &&
                    !prompt.usertypes?.includes("Teacher") &&
                    !prompt.usertypes?.includes("Admin")
                  )
                    return false;

                  // hide teacher prompts from students
                  if (
                    authContext.permission?.student &&
                    !prompt.usertypes?.includes("Student")
                  ) {
                    return false;
                  }
                  // show these only if it is a super account
                  if (
                    (prompt.usertypes?.includes("Tyro") &&
                      !authContext.permission?.supertyro) ||
                    (prompt.active == false &&
                      !authContext.permission?.supertyro)
                  )
                    return false;

                  var favorited = prompt.topics.includes("favorites");

                  return (
                    <div
                      onClick={() => navigate("/ai/Chat/" + prompt.id)}
                      className={prompt.active ? "active" : "notActive"}
                      key={prompt.id}
                    >
                      <SmallCard
                        title={prompt.title}
                        restrictWidth
                        className="promptCard"
                      >
                        {prompt.topics.includes("featured") ? (
                          <div className="notificationCharm">1</div>
                        ) : null}

                        {/* Thought bubble - not using for now
                        <div className="thought-bubble">
                          <div className="circle small"></div>
                          <div className="circle medium"></div>
                          <div className="circle large"></div>
                        </div>
                        */}
                        <div className="thumbnail">
                          <img src={prompt.thumbnail_url} />
                        </div>
                        <p className="promptMsg">{prompt.description}</p>

                        <div className="buttonBar">
                          {authContext.permission?.supertyro && (
                            <Button
                              icon="edit"
                              action={() =>
                                navigate("/prompt/designer/" + prompt.id)
                              }
                            />
                          )}

                          <FavoriteButton
                            prompt_id={prompt.id}
                            toggleFavorite={toggleFavorite}
                            favorited={favorited}
                          />
                          <Button
                            icon="share"
                            action={() =>
                              share("/ai/Chat/" + prompt.id, prompt.title)
                            }
                          />
                        </div>
                      </SmallCard>
                    </div>
                  );
                })}
              </Grid>
            </div>
          );
        })}
      </Animate>
      <div id="snackbar"> </div>
      {showShare && (
        <Share url={shareURL} hide={hideShare} title={shareTitle} />
      )}
      <Feedback />
    </>
  );
}
