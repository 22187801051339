/***
 *
 *   BILLING
 *   Change subscription, update card details or view past invoices
 *
 **********/

import React, { Fragment, useContext, useState, useEffect } from "react";
import {
  AuthContext,
  Header,
  TabView,
  Message,
  Animate,
  useAPI,
} from "components/lib";

import { BillingPlan } from "./plan";
import { BillingCard } from "./card";
import { BillingInvoices } from "./invoices";

const Messages = require("./messages.json");

export function Billing(props) {
  // state/context
  const context = useContext(AuthContext);
  const [showMessage, setShowMessage] = useState(false);

  // fetch subscription
  const subscription = useAPI("/api/account/subscription");
  const isPaid = context.user.plan === "free" ? false : true;
  const labels = ["Plan"];
  if (isPaid) labels.push("Card", "Invoices");

  useEffect(() => {
    // subscription did load - show message?
    if (
      subscription.data &&
      subscription.data.status !== "active" &&
      subscription.data.status !== "trialing"
    )
      setShowMessage(true);
  }, [subscription.data]);

  return (
    <Fragment>
      <Header />
      <Animate>
        {!subscription?.loading &&
          subscription?.data?.status !== "active" &&
          subscription?.data?.status !== "trialing" && (
            <Message {...Messages[subscription.data?.status]} />
          )}

        {showMessage && <Message {...Messages[subscription.data?.status]} />}

        <BillingPlan
          subscription={subscription}
          onUpdate={() => setShowMessage(false)}
        />

        {isPaid && <BillingCard />}

        {isPaid && <BillingInvoices />}
      </Animate>
    </Fragment>
  );
}
