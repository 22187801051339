export const LogoSVG = (props) => (
  <a href={props.link} className={props.className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_2"
      viewBox="0 0 257.75 88.65"
      className={"logoSVG"}
    >
      <g className="tyr">
        <path
          d="M131.6,158.9L164,327.6c0.7,3.9-1.9,7.7-5.8,8.4l-45.5,8.5c-3.9,0.7-7.7-1.9-8.4-5.8L72,170c-0.7-3.9-4.5-6.5-8.4-5.8
			l-48.2,7.6c-3.9,0.7-7.7-1.9-8.4-5.8l-6.9-39.5c-0.7-3.9,1.9-7.7,5.8-8.4L173.2,89c3.9-0.7,7.7,1.8,8.4,5.8l6.9,39.5
			c0.7,3.9-1.9,7.7-5.8,8.4l-45.3,7.8C133.5,151.2,130.9,154.9,131.6,158.9L131.6,158.9L131.6,158.9z"
        />
        <path
          d="M266.7,264.3l7.5,54.2c0.5,3.5-2,6.8-5.5,7.3l-41.1,5.7c-3.5,0.5-6.8-2-7.3-5.5l-7.4-53.5c-0.1-0.9-0.5-1.8-1-2.6
			l-65.1-100.1c-2.6-4-0.2-9.3,4.5-9.9l47.2-6.5c2.7-0.4,5.4,1,6.7,3.5l25,49.4c1.9,3.6,3.2,6.8,4.2,9.6c0.9,2.8,1.9,5.5,3,8.4
			l0.5-0.1c0-3.6,0.1-6.9,0.3-9.9s0.5-5.8,0.9-8.2l10.3-55.3c0.5-2.7,2.7-4.8,5.5-5.2l42.1-5.8c4.7-0.7,8.5,3.9,7,8.4l-37.1,113.4
			C266.6,262.3,266.5,263.3,266.7,264.3L266.7,264.3L266.7,264.3z"
        />
        <path
          d="M422.7,314c-2.3,0.2-4.6-0.9-5.9-2.9L391.1,272c-2-3.1-3.9-5.8-5.6-8.1c-1.7-2.2-3.4-4.1-5-5.5c-1.6-1.4-3.2-2.5-4.9-3.1
			c-1.6-0.6-3.5-0.8-5.5-0.7l0,0c-3.5,0.3-6.2,3.4-5.9,6.9l4.2,50.4c0.3,3.5-2.3,6.6-5.9,6.9l-41.4,3.5c-3.5,0.3-6.6-2.3-6.9-5.9
			l-14.1-167.1c-0.3-3.5,2.3-6.6,5.9-6.9l65.6-5.5c25.2-2.1,44.1,0.4,56.8,7.6s19.7,19.1,21.1,35.6c0.6,6.8,0.1,12.9-1.5,18.5
			c-1.5,5.6-3.9,10.6-7.1,15c-3.2,4.4-7.1,8.3-11.7,11.6c-4.6,3.3-9.8,6.1-15.4,8.3l0.1,1.1c2.9,0.9,5.5,2.1,7.9,3.4
			s4.8,2.9,7.1,4.7c2.3,1.8,4.6,4,6.9,6.5c2.3,2.5,4.6,5.5,7.1,8.9l30.2,41.6c2.9,4,0.3,9.8-4.7,10.2L422.7,314L422.7,314L422.7,314
			z M392.8,192.8c-0.5-6-2.8-10.6-6.7-13.8c-4-3.2-9.9-4.5-17.8-3.8l-4.8,0.4c-3.5,0.3-6.2,3.4-5.9,6.9l2.4,28.4
			c0.3,3.5,3.4,6.2,6.9,5.9l3.4-0.3c4.2-0.4,7.7-1.3,10.7-2.9c2.9-1.6,5.3-3.4,7.1-5.6c1.8-2.2,3.1-4.6,3.8-7.3
			C392.7,198,393,195.4,392.8,192.8L392.8,192.8L392.8,192.8z"
        />
      </g>
      <g className={"bot"}>
        <g>
          <path
            class="cls-1"
            d="M231.59,230.65c0,62.6,8.17,112.95-110.71,114.17C-14.32,346.21,1.11,279.75.24,215.68c-.87-64.07-5.6-113.6,129.29-115.68,102.43-1.58,102.07,54.39,102.07,130.65ZM175.54,153.12c-15.85-11-95.23-11.03-114.27,3.26-19.04,14.28-20.23,119.02-2.38,135.69,17.85,16.66,101.17,26.78,126.16-8.93,11.18-15.97,7.18-118.43-9.51-130.02Z"
          />
        </g>
        <g id="Isolation_Mode">
          <path
            class="cls-1"
            d="M118.93,277.73c-26.08.86-29.11-14.6-28.75-23.37.13-3.28,2.88-5.84,6.17-5.84h44.07c3.16,0,5.86,2.37,6.14,5.51.74,8.22-1.63,22.84-27.62,23.69Z"
          />
          <path
            class="cls-1"
            d="M88.36,184.6c11.72-5.26,12.26,25.35,4.53,28.6-10.87,4.57-12.66-24.95-4.53-28.6Z"
          />
          <line class="cls-1" x1="104.85" y1="108.34" x2="93.55" y2="70.65" />
          <rect
            class="cls-1"
            x="91.94"
            y="69.82"
            width="14.53"
            height="39.34"
            transform="translate(-21.53 32.28) rotate(-16.7)"
          />
          <rect
            class="cls-1"
            x="68.29"
            y="34.09"
            width="45.48"
            height="45.48"
            rx="22.74"
            ry="22.74"
          />
          <rect
            class="cls-1"
            x="39.96"
            y="70.56"
            width="21.61"
            height="14.53"
            transform="translate(-35.78 44) rotate(-35.54)"
          />
          <rect
            class="cls-1"
            x="37.74"
            y="31.32"
            width="14.53"
            height="23.47"
            transform="translate(-8.53 74.89) rotate(-74.48)"
          />
          <rect
            class="cls-1"
            x="67.44"
            y="1.8"
            width="14.53"
            height="25.9"
            transform="translate(.13 30.15) rotate(-22.83)"
          />
          <rect
            class="cls-1"
            x="110.22"
            y="15.06"
            width="21.32"
            height="14.53"
            transform="translate(19.62 92.02) rotate(-45)"
          />
          <rect
            class="cls-1"
            x="128.59"
            y="52.06"
            width="14.53"
            height="27.81"
            transform="translate(30.31 173.98) rotate(-71.57)"
          />
          <path
            class="cls-1"
            d="M142.45,184.6c11.72-5.26,12.26,25.35,4.53,28.6-10.87,4.57-12.66-24.95-4.53-28.6Z"
          />
        </g>
      </g>
    </svg>
  </a>
);
