/***
 *
 *   HEADER
 *   Header section with title used in main application (can render children)
 *
 *   PROPS
 *   title: title of the view
 *   children: children to render (optional)
 *
 **********/
import { useNavigate } from "components/lib";

export function Header(props) {
  const navigate = useNavigate();

  return (
    <header className={props.ClassName}>
      <button type="button" onClick={() => navigate(-1)}>
        &lt; Back
      </button>
      {props.title && <h1>{props.title}</h1>}
      {props.children}
    </header>
  );
}
