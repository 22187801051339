/***
 *
 *   SETTINGS
 *   Update the account settings
 *
 **********/

import React, { Fragment, useContext } from "react";
import {
  AuthContext,
  ViewContext,
  Form,
  Card,
  Header,
  useNavigate,
  Animate,
  useAPI,
} from "components/lib";

export function Settings(props) {
  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  // fetch

  return (
    <Fragment>
      <Header />
      <Animate>
        <Card title="Settings" restrictWidth></Card>
      </Animate>
    </Fragment>
  );
}
