/***
 *
 *   ONBOARDING
 *   Example of onboarding flow*
 *
 **********/

import React, { Fragment, useContext } from "react";
import { AuthContext, Onboarding, Form, Message } from "components/lib";

export function OnboardingView(props) {
  const context = useContext(AuthContext);
  const views = [
    {
      name: "Getting Started",
      description: `Hi, ${context.user.name}! Welcome to Tyro`,
      component: <Welcome />,
    },
  ];

  if (context.permission.account_admin && "FAL todo" === "true") {
    views.push({
      name: "Invite others",
      description: "Tyro is more fun when you invite your team.",
      component: <InviteUsers />,
    });
  }

  if (context.user.duplicate_user) {
    views.unshift({
      name: "Important!",
      description: "",
      component: <DuplicateUser />,
    });
  }

  return <Onboarding save onFinish="/ai/Chat" views={views} />;
}

function DuplicateUser() {
  return (
    <Message
      type="warning"
      title={`You already have an account`}
      text="We noticed you have already registered an account, so we used your original password to save you any confusion in the future."
    />
  );
}

function Welcome() {
  return (
    <Fragment>
      <p>
        We are excited to have you on board as a beta tester. This version of
        the app uses conversation prompts designed to help teachers with common
        tasks and students with common questions.
      </p>

      <p>
        <strong>
          Do you have a good idea to share or have you found a bug? Use the
          heart on the bottom right of your screen to provide feedback.{" "}
        </strong>
      </p>
    </Fragment>
  );
}

function InviteUsers(props) {
  return (
    <Form
      inputs={{
        email: {
          label: "Email",
          type: "email",
          required: true,
        },
      }}
      buttonText="Send Invite"
      url="/api/invite"
      method="POST"
    />
  );
}
