/***
 *
 *   SIGN UP (user)
 *   Signup form for child users
 *
 **********/

import React, { useContext } from "react";
import {
  Animate,
  AuthContext,
  Card,
  Form,
  Link,
  SocialSignin,
} from "components/lib";

export function SignupUser(props) {
  const context = useContext(AuthContext);
  const url = window.location.href;
  const id = url.substring(url.indexOf("?id=") + 4, url.indexOf("&email"));
  const email = url.substring(url.indexOf("&email") + 7);

  return (
    <Animate type="pop">
      <Card restrictWidth center title="Create Your Account">
        <Form
          inputs={{
            name_first: {
              label: "First Name",
              value: "",
              type: "text",
              required: true,
              errorMessage: "Please enter your first name",
            },
            name_last: {
              label: "Last Name",
              value: "",
              type: "text",
              required: true,
              errorMessage: "Please enter your last name",
            },
            email: {
              label: "Email",
              value: email,
              type: "email",
              required: true,
            },
            password: {
              label: "Password",
              type: "password",
              required: true,
              complexPassword: false,
            },
            confirm_password: {
              type: "hidden",
              value: null,
            },
            invite_id: {
              type: "hidden",
              value: id,
            },
          }}
          url="/api/user"
          method="POST"
          redirect="/dashboard"
          buttonText="Create Account"
          callback={context.signin}
        />
      </Card>
      <div className="mt-4">
        <br />
        Already registered? <Link url="/signin" text="Sign In" />
      </div>
    </Animate>
  );
}
