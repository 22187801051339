import { Account } from "views/account";
import { Profile } from "views/account/profile-tyro";
import { Billing } from "views/account/billing";
import { Settings } from "views/account/settings";
import { Upgrade } from "views/account/upgrade";
import { Users } from "views/account/users-tyro";
import { Password } from "views/account/password";
import { TwoFA } from "views/account/2fa";

const Routes = [
  {
    path: "/account",
    view: Account,
    layout: "app",
    permission: "user",
    title: "Your Account",
  },
  {
    path: "/account/profile",
    view: Profile,
    layout: "app",
    permission: "user",
    title: "Your Profile",
  },
  {
    path: "/account/password",
    view: Password,
    layout: "app",
    permission: "user",
    title: "Change Password",
  },
  {
    path: "/account/2fa",
    view: TwoFA,
    layout: "app",
    permission: "user",
    title: "Manage 2FA",
  },
  {
    path: "/account/billing",
    view: Billing,
    layout: "app",
    permission: "owner",
    title: "Billing",
  },
  {
    path: "/account/settings",
    view: Settings,
    layout: "app",
    permission: "account_admin",
    title: "Settings",
  },
  {
    path: "/account/upgrade",
    view: Upgrade,
    layout: "app",
    permission: "owner",
    title: "Upgrade",
  },
  {
    path: "/account/users",
    view: Users,
    layout: "app",
    permission: "account_admin",
    title: "Users",
  },
];

export default Routes;
