/***
 *
 *   BILLING
 *   Update card details
 *
 **********/

import React, { useState, useEffect } from "react";
import {
  OrganizationCard,
  CreditCard,
  PaymentForm,
  useAPI,
  Header,
} from "components/lib";

export function BillingCard(props) {
  const [card, setCard] = useState(null);
  const data = useAPI("/api/account/card");

  useEffect(() => {
    if (data.data) setCard(data.data);
  }, [data.data]);

  return (
    <>
      <Header />
      <OrganizationCard
        restrictWidth
        loading={data.loading}
        className={props.className}
      >
        {card && (
          <CreditCard
            brand={card.brand}
            last_four={card.last4}
            expires={`${card.exp_month}/${card.exp_year}`}
          />
        )}

        <PaymentForm
          className="restrict-width"
          inputs={{
            token: {
              label: "Update Your OrganizationCard",
              type: "creditcard",
              required: true,
            },
          }}
          url="/api/account/card"
          method="PATCH"
          callback={(res) => setCard(res.data.data)}
          buttonText="Save OrganizationCard"
        />
      </OrganizationCard>
    </>
  );
}
