/***
 *
 *   SNACK
 *   Snack notification that appears at the bottom of the screen.
 *   Create a snack notification anytime using context.snack.show()
 *   and pass the props below
 *
 *   PROPS
 *   message: string containing the message
 *   type - success/error/warning
 *   autoclose - if false, the notification won't disapear until clicking the X
 *
 **********/

import { useContext } from "react";
import { ViewContext, Button, Icon } from "components/lib";
import { CSSTransition } from "react-transition-group";
import "./snack.scss";

export function Snack(props) {
  const context = useContext(ViewContext);
  const data = context.snack.data;
  let _class = "snack";
  return <div className={`snack ${props.type}`}>{data.text}</div>;
}
