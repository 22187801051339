/***
 *
 *   HOMEPAGE
 *   Homepage template with features, testimonials and CTA
 *
 **********/

import React, { Fragment, useContext, useRef } from "react";
import { Logo, AuthContext, Button, LoaderChat, Card } from "components/lib";
import Axios from "axios";

export function Home(props) {
  const context = useContext(AuthContext);

  // kinda like getElementById
  const loaderRef = useRef(null);
  const messageRef = useRef(null);
  const formRef = useRef(null);

  var submitting = false;

  async function sendEmail(event) {
    // Prevent the browser from reloading the page
    event.preventDefault();

    var message = "";
    const form = event.target;
    var formData = {};

    for (let i = 0; i < form.elements.length; i++) {
      const element = form.elements[i];
      formData[element.name] = element.value;
    }

    if (submitting) return;

    submitting = true;
    formRef.current.style.opacity = "0";
    loaderRef.current.style.opacity = "1";

    await Axios({
      method: "post",
      url: "/api/utility/mailWaitList",
      params: formData,
    })
      .then((response) => {
        message = "Thank you. We'll be in touch soon.";
      })
      .catch((error) => {
        message = "An error occurred. Please try again later.";
      });

    setTimeout(() => {
      loaderRef.current.style.opacity = "0";
      messageRef.current.style.opacity = "1";
      messageRef.current.textContent = message;
    }, 1000);

    submitting = false;
  }

  return (
    <div className="content">
      <Logo logo color className="logo" />

      <Card className="instructions" center>
        <div className="message" ref={messageRef}></div>

        <div className="header">
          <h2>We're giving teachers and students AI superpowers!</h2>

          <div>
            <span className="hello"> &nbsp;</span>
            <Button small goto="/ai/Chat" rounded text="Join Us!" />
          </div>
        </div>
      </Card>
    </div>
  );
}
