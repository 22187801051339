/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *  Modifying the orginal gravity profile page so it is easier to merge with
 *  gravity git updates in the future
 *
 **********/

import React, { Fragment, useContext } from "react";
import {
  AuthContext,
  ViewContext,
  Form,
  OrganizationCard,
  Header,
  Button,
  useNavigate,
  Animate,
  useAPI,
  Event,
} from "components/lib";

export function Profile(props) {
  const navigate = useNavigate();

  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  // fetch
  const user = useAPI("/api/user");

  function closeAccount() {
    viewContext.modal.show(
      {
        title: "Close Your Account",
        form: {},
        buttonText: "Close Account",
        url: authContext.permission.owner ? "/api/account" : "/api/user",
        method: "DELETE",
        destructive: true,
        text:
          "Are you sure you want to delete your account? " +
          "You will lose all of your data and this can not be undone.",
      },
      () => {
        // destory user
        Event("closed_account");
        localStorage.clear();
        navigate("/signup");
      }
    );
  }

  return (
    <Fragment>
      <Header />
      <Animate>
        <OrganizationCard
          title="Account: Profile"
          loading={user.loading}
          restrictWidth
        >
          {user?.data && (
            <Form
              buttonText="Save"
              url="/api/user"
              method="PATCH"
              inputs={{
                name_first: {
                  label: "First Name",
                  type: "text",
                  required: true,
                  value: user.data.name_first,
                  errorMessage: "Please enter your first name",
                },
                name_last: {
                  label: "Last Name",
                  type: "text",
                  required: true,
                  value: user.data.name_last,
                  errorMessage: "Please enter your last name",
                },
                email: {
                  label: "Email address",
                  type: "email",
                  required: true,
                  value: user.data.email,
                  errorMessage: "Please enter your email address",
                },
                ...(user.data.accounts?.length > 1 && {
                  default_account: {
                    label: "Default Account",
                    type: "select",
                    default: user.data.default_account,
                    options: user.data.accounts.map((x) => {
                      return {
                        value: x.id,
                        label: x.name,
                      };
                    }),
                  },
                }),
              }}
              callback={(res) => {
                // update the user name and avatar
                authContext.update({
                  name_first: res.data.data.name_first,
                  name_last: res.data.data.name_last,
                  avatar: res.data.data.avatar,
                });
              }}
            />
          )}
        </OrganizationCard>
        {authContext.permission?.account_admin && (
          <>
            <Button
              textOnly
              action={closeAccount}
              color="red"
              text="Close Your Account"
            />
            <br /> <br />
          </>
        )}
      </Animate>
    </Fragment>
  );
}
