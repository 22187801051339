import { Badge, Link, useNavigate } from "components/lib";
import { RowActions } from "./actions";
import Style from "./organizationTable.module.scss";

export function OrganizationTableBody(props) {
  if (props.data?.length) {
    return (
      <tbody className={Style.body}>
        {props.data.map((row, index) => {
          return <Row {...props} data={row} key={index} />;
        })}
      </tbody>
    );
  }

  return (
    <tbody className={Style.body}>
      <tr>
        <td colSpan="10" className={Style.empty}>
          No results found
        </td>
      </tr>
    </tbody>
  );
}

export function Row(props) {
  const navigate = useNavigate();

  let row = { ...props.data };
  row.actions = row.actions || props.actions;
  const hasActions = Object.values(row.actions).some((x) => x !== undefined);

  // FAL allows us to send a link and values for the entire row
  function gotoPage() {
    if (props.link) {
      let url = props.link.url;
      if (props.link.school_id) url += "/school/" + row[props.link.school_id];
      if (props.link.classroom_id)
        url += "/classroom/" + row[props.link.classroom_id];
      if (props.link.id) url += row[props.link.id];

      navigate(url);
    }
  }

  return (
    <tr
      data-id={props.data.id}
      onClick={() => {
        gotoPage();
      }}
    >
      {Object.keys(row).map((cell, index) => {
        // actions
        if (cell === "actions")
          return hasActions ? (
            <RowActions
              row={row}
              index={index}
              key={index}
              callback={props.callback}
            />
          ) : (
            false
          );

        // hide
        if (props.hide?.includes(cell)) return false;

        // show
        if (props.show && !props.show.includes(cell)) return false;

        let value = row[cell];

        // is date/time
        if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value)) {
          const date = new Date(value).toISOString().split("T");
          value = `${date[0]} ${date[1].split(".")[0]}`;
        }

        // has badge
        if (value !== undefined && props.badge && cell === props.badge.col) {
          // default color
          let color = props.badge.color;
          let displayText =
            value === true ? "Yes" : value === false ? "No" : value;

          // check each condition
          if (props.badge.condition) {
            props.badge.condition.forEach((cond) => {
              if (typeof cond.value === "string" && typeof value === "string") {
                if (cond.value.toLowerCase() === value.toLowerCase()) {
                  color = cond.color;
                  if (cond.text) {
                    displayText = cond.text;
                  }
                }
              } else if (cond.value === value) {
                color = cond.color;
                if (cond.text) {
                  displayText = cond.text;
                }
              }
            });
          }

          return (
            <td key={index} className={Style.cell}>
              <Badge text={displayText} color={color} className={Style.badge} />
            </td>
          );
        }

        //name cell and link
        if (cell === "name") {
          return (
            <td key={index} className={Style.cell}>
              {value}
            </td>
          );
        }

        // standard cell
        return (
          <td key={index} className={Style.cell}>
            {value === true ? "Yes" : value === false ? "No" : value}
          </td>
        );
      })}
    </tr>
  );
}
