/***
 *
 *   STUDENT Page
 *   view all details of a student
 *
 **********/

import { useParams } from "react-router-dom";

import {
  OrganizationCard,
  Animate,
  OrganizationTable,
  useAPI,
  Header,
} from "components/lib";

export function Student(props) {
  const params = useParams();

  console.log(" id: " + params.id);
  //get the student
  const student = useAPI("/api/user/student/" + params.id) || [];

  const history = useAPI("/api/history/" + params.id) || [];
  console.log(history?.data);

  return (
    <>
      <Header />

      <header className="content-header">
        <h1>
          Student: <span>{student.data?.name}</span>
        </h1>
      </header>
      <Animate>
        <OrganizationCard loading={student.loading} title={"About"}>
          <b>Grade: </b>
          {student.data?.grade}
          <br />
          {!student.data?.gender ? null : (
            <div>
              <b>Gender: </b>
              {student.data?.gender}
              <br />
            </div>
          )}

          {!student.data?.date_of_birth ? null : (
            <div>
              <b>Birthday: </b>
              {student.data?.date_of_birth}
              <br />
              <b>Email: </b>
              {student.data?.email}
              <br />
            </div>
          )}
          <b>Last Active: </b>
          {new Date(student.data?.last_active).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
          <br />
        </OrganizationCard>
        <OrganizationCard loading={history.loading} title="History">
          <OrganizationTable
            search
            className="single_action"
            loading={history?.loading}
            data={history?.data}
            show={["title", "prompt_title"]}
            link={{
              url: "/account/organization/history/",
              id: "id",
            }}
          />
        </OrganizationCard>
      </Animate>
    </>
  );
}
