/***
 *
 *   TEACHERS Page
 *   view the details of a teacher
 *
 **********/

import { useParams } from "react-router-dom";

import {
  OrganizationCard,
  Header,
  Animate,
  OrganizationTable,
  useAPI,
} from "components/lib";

export function Teacher(props) {
  const params = useParams();

  console.log(" id: " + params.id);
  //get the teacher
  const teacher = useAPI("/api/user/teacher/" + params.id) || [];
  const history = useAPI("/api/history/" + params.id) || [];
  return (
    <>
      <Header />

      <header className="content-header">
        <h1>
          Teacher: <span>{teacher.data?.name}</span>
        </h1>
      </header>
      <Animate>
        <OrganizationCard loading={teacher.loading} title={"About"}>
          <b>Email: </b>
          {teacher.data?.email}
          <br />
          <b>Last Active: </b>
          {new Date(teacher.data?.last_active).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
          <br />
        </OrganizationCard>
        <OrganizationCard loading={history.loading} title="History">
          <OrganizationTable
            search
            loading={history?.loading}
            data={history?.data}
            show={["title", "date"]}
            link="/account/organization/history"
          />
        </OrganizationCard>
      </Animate>
    </>
  );
}
