/***
 *
 *   EXPANDABLESECTION
 *   Used to create a section that can be expanded or collapsed
 *
 *   PROPS
 *   title: title string (optional)
 *   expanded: boolean (optional)
 *
 **********/

import { useState } from "react";
import { Icon, ClassHelper } from "components/lib";
import Style from "./expandableSection.module.scss";

export function ExpandableSection(props) {
  const [isExpanded, setIsExpanded] = useState(props.expanded);
  const cardStyle = ClassHelper(Style, {
    className: props.className,
    transparent: props.transparent,
    expanded: props.expanded,
  });

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section className={cardStyle}>
      <div className={Style.header} onClick={toggleExpanded}>
        {props.title && <label className={Style.title}>{props.title}</label>}
        <div className={Style.expandBtn}>
          <Icon
            className={Style.chevron}
            image={isExpanded ? "chevron-up" : "chevron-down"}
          />
        </div>
      </div>
      <div className={isExpanded ? "" : "hidden"}>{props.children}</div>
    </section>
  );
}
