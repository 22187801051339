/***
 *
 *   CONTACT FORM
 *   Will send an email notification to you
 *
 **********/

import React from "react";
import { Animate, Card, ContactForm } from "components/lib";

export function Contact(props) {
  return (
    <Animate type="pop">
      <Card title="Contact Us" className="contactCard">
        <ContactForm />
      </Card>
    </Animate>
  );
}
