/***
 *
 *   EXPANDABLECARD
 *   Used to create a sub card with a title and collapsable content
 *
 *   PROPS
 *   title: title string (optional)
 *
 **********/

import { useState } from "react";
import { Button, ClassHelper } from "components/lib";
import Style from "./expandableCard.module.scss";

export function ExpandableCard(props) {
  const [isExpanded, setIsExpanded] = useState(props.expanded);
  const cardStyle = ClassHelper(Style, {
    noPadding: props.noPadding,
    loading: props.loading,
    className: props.className,
    transparent: props.transparent,
    expanded: props.expanded,
    onRemove: props.onRemove,
  });

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section className={cardStyle}>
      <div className={Style.header}>
        {props.title && <h3 className={Style.title}>{props.title}</h3>}
        <Button
          action={toggleExpanded}
          icon={isExpanded ? "chevron-up" : "chevron-down"}
          className={Style.expandBtn}
        />

        {props.onRemove && (
          <button onClick={props.onRemove} className="removeBtn">
            Remove
          </button>
        )}
      </div>
      <div className={isExpanded ? "" : "hidden"}>{props.children}</div>
    </section>
  );
}
