/***
 *
 *   Advanced Chat component
 *   Wrapper component to add advanced elements to a chat prompt
 *
 *
 **********/

import { useState, useEffect, useContext } from "react";
import { Form, useAPI, ViewContext } from "components/lib";
import axios from "axios";

export function AdvancedChatParams(props) {
  const context = useContext(ViewContext);
  const componentData = useAPI(
    props.id ? `/api/prompt/component/chat_advanced_params/${props.id}` : null
  );

  async function onRemove() {
    console.log("Goodbye", props.id);
    if (props.id) {
      await axios.delete(
        `/api/prompt/component/chat_advanced_params/${props.id}`
      );
      context.snack.show("Advanced chat params deleted", "success");
    }
    props.onRemove();
  }

  return (
    <>
      {!componentData.loading && (
        <Form
          inputs={{
            prompt_id: {
              type: "hidden",
              value: props.id,
            },
            max_tokens: {
              label: "Max tokens (1000-10000)",
              type: "number",
              min: 100,
              max: 10000,
              value: componentData?.data?.max_tokens,
            },
            top_p: {
              label: "Top_p (0-2)",
              type: "number",
              min: 0,
              max: 2,
              value: componentData?.data?.top_p,
            },

            temperature: {
              label: "Temperature(0-2)",
              type: "number",
              min: 0,
              max: 2,
              value: componentData?.data?.temperature,
            },
            n: {
              label: "n (0-1)",
              type: "number",
              min: 0,
              max: 1,
              value: componentData?.data?.n,
            },
            presence_penalty: {
              label: "Presence Penalty (0-1)",

              type: "number",
              min: 0,
              max: 1,
              value: componentData?.data?.presence_penalty,
            },
            frequency_penalty: {
              label: "Frequency Penalty (0-1)",
              type: "number",
              min: 0,
              max: 1,
              value: componentData?.data?.frequency_penalty,
            },
          }}
          method="PATCH"
          url="/api/prompt/component/chat_advanced_params"
          buttonText="Save"
          callback={() => context.snack.show("Advanced chat params updated")}
        />
      )}

      {props.onRemove && (
        <button onClick={onRemove} className="removeBtn">
          Remove
        </button>
      )}
    </>
  );
}
