/***
 *
 *   HELP
 *   Information for user to get help and support
 *
 **********/

import React, { Fragment } from "react";
import { Card, Form, Message, Loader, useAPI } from "components/lib";

export function Help(props) {
  const user = useAPI("/api/user");

  return (
    <Fragment>
      {user.loading ? (
        <Loader />
      ) : (
        <Card restrictWidth className="help">
          <header>
            <h2>Contact Support</h2>
          </header>
          {/* <Form
            inputs={{
              support_enabled: {
                type: "radio",
                options: ["Yes", "No"],
                required: true,
                errorMessage: "Please enter a message",
                default: user.data?.support_enabled ? "Yes" : "No",
                label: "Can our support team access your account?",
              },
            }}
            submitOnChange
            method="PATCH"
            url="/api/user"
            className="helpForm"
            />
            */}
          <Form
            inputs={{
              email: {
                type: "hidden",
                value: user?.data?.email,
              },
              name: {
                type: "hidden",
                value: user?.data?.name,
              },
              template: {
                type: "hidden",
                value: "help",
              },
              message: {
                type: "textarea",
                label: "What can we help with?",
                errorMessage: "Please enter a message",
                required: true,
              },
            }}
            method="POST"
            url="/api/utility/mail"
            buttonText="Send Message"
          />
        </Card>
      )}
    </Fragment>
  );
}
