/***
 *
 *   SCHOOL Page
 *   view the school data including classrooms
 *   depending on permisisons show all classes (admin) or teacher classes
 *
 **********/

import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "components/lib";

import Axios from "axios";

import {
  OrganizationCard,
  Header,
  OrganizationTable,
  useAPI,
} from "components/lib";

export function TeacherSchool(props) {
  const params = useParams();
  const context = useContext(AuthContext);

  // get district
  const classes = useAPI("/api/account/teacher/classes");
  console.log("classes", classes.data);
  const classesList = ["name", "subject", "periods", "teacher_name"];

  return (
    <>
      <Header />

      <header className="content-header">
        <h1>
          Classes: <span>{context.user.name}</span>
        </h1>
      </header>

      <OrganizationCard title={"Classes " + classes?.data?.length}>
        <OrganizationTable
          search
          className="single-action"
          loading={classes?.loading}
          data={classes?.data}
          show={classesList}
          hide={["school_source_id", "source_id"]}
          linkId="source_id"
          link={{
            url: "/account/organization",
            school_id: "school_source_id",
            classroom_id: "source_id",
          }}
        />
      </OrganizationCard>
    </>
  );
}
