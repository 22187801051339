/***
 *
 *   CREATE A PROMPT
 *   Signup form for account owners
 *   Step 1: choose the type of prompt
 *   Step 2: design the prompt
 *
 *
 **********/

import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  ExpandableCard,
  ExpandableSection,
  OrganizationCard,
  PromptThumbnail,
  AdvancedChatParams,
  Button,
  ViewContext,
  Header,
} from "components/lib";

// config
let topics = require("../../app/topics").topics;
topics = topics.map(({ value, label }) => ({ value, label }));

const usertypes = require("../../app/usertypes").usertypes;

///prop function
export function Designer(props) {
  const context = useContext(ViewContext);

  // for the redirect after form submission
  const navigate = useNavigate();

  //get the params
  const params = useParams();

  // form state
  const [formData, setFormData] = useState({});

  //topics state
  const [topic, setTopic] = useState([]);

  // usertype state
  const [usertype, setUsertype] = useState([]);

  //messages state
  const [messages, setMessages] = useState([]);

  //store if the form is in edit mode
  const [isEditMode, setEditMode] = useState(!!params.id);

  useEffect(() => {
    //only used for populating the form when editing a prompt
    if (params.id) {
      Axios.get(`/api/prompt/${params.id}`)
        .then((response) => {
          const prompt = response.data.data;

          if (prompt) setFormData(prompt);
          if (prompt.messages) setMessages(prompt.messages);
          if (prompt.topics) setTopic(prompt.topics);
          if (prompt.usertypes) setUsertype(prompt.usertypes);
          if (prompt.components) addComponentFromPrompt(prompt.components);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [params]);

  // temporary state for form submission status
  const [submitting, setSubmitting] = useState(false);

  //messages state -- these are dynamic and can be added or removed

  //////message handlers
  const handleMessageChange = (index, event) => {
    const content = event.target.value;
    const list = [...messages];
    list[index].content = content;
    setMessages(list);
  };

  //add a new message and set role as user or assistant
  function addMessage() {
    //set default role to user
    var prevRole = "assistant";

    //if there are messages, set the role to the opposite of the last message
    if (messages.length > 0) prevRole = messages[messages.length - 1].role;

    setMessages([
      ...messages,
      {
        role: prevRole === "user" ? "assistant" : "user",
        content: "",
      },
    ]);
  }

  //remove a message based on the index passed from the button
  function removeMessage(index) {
    const tempMessages = [...messages];
    tempMessages.splice(index, 1);
    setMessages(tempMessages);
  }
  //////end message handlers

  //added to all inputs to update the state

  const handleChange = (event) => {
    const { name, type, checked, value } = event.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData({ ...formData, [event.target.name]: newValue });
  };

  const handleTopicChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      // push selected value in list
      setTopic((prev) => [...prev, value]);
    } else {
      // remove unchecked value from the list
      setTopic((prev) => prev.filter((x) => x !== value));
    }
  };

  const handleUsertypeChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      // push selected value in list
      setUsertype((prev) => [...prev, value]);
    } else {
      // remove unchecked value from the list
      setUsertype((prev) => prev.filter((x) => x !== value));
    }
  };

  async function handleSubmit(event) {
    console.log("submitting form", params.id);
    setSubmitting(true);

    setTimeout(() => {
      setSubmitting(false);
    }, 1000);

    //post the form data to the api depending on if it is a new prompt or an edit
    const postUrl = isEditMode
      ? `/api/prompt/update/${params.id}`
      : "/api/prompt";

    //add the messages, topics, and usertypes to the form data
    const dataTemp = formData;

    // data clean up ... components update themselves need to remove from this post
    // these are just part of the orginal prompt data that the form uses
    delete dataTemp.components;
    delete dataTemp.thumbnail_url;
    delete dataTemp.thumbnail_prompt;
    delete dataTemp.date_created;

    if (formData.model === "gpt-4" || formData.model === "gpt-3.5-turbo")
      dataTemp.messages = messages;
    dataTemp.topics = topic;
    dataTemp.usertypes = usertype;

    await Axios({
      method: isEditMode ? "patch" : "post",
      url: postUrl,
      data: dataTemp,
    })
      .then((response) => {
        context.snack.show(
          `Prompt ${isEditMode ? "edited" : "created"} successfully`
        );

        if (!isEditMode) {
          setEditMode(true);
          navigate(`/prompt/designer/${response.data.id}`);
        }
      })
      .catch((error) => {
        context.snack.show(
          `Error ${isEditMode ? "editing" : "creating"}  prompt`
        );
      });
  } //end handleSubmit

  async function deletePrompt(id) {
    await Axios({
      method: "delete",
      url: `/api/prompt/${id}`,
      params: {
        id: id,
      },
    })
      .then((response) => {
        context.snack.show("prompt deleted");
        console.log(response);
        setTimeout(function () {
          navigate("/prompts");
        }, 1000);
      })
      .catch((error) => {
        context.snack.show("error deleting prompt");
      });
  }

  // component management
  const [currentComponents, setCurrentComponents] = useState([]);

  function addComponentFromPrompt(promptComponents) {
    const componentTypes = promptComponents.map(
      (component) => component.component_type
    );
    setCurrentComponents(componentTypes);
  }

  const addComponent = (component) => {
    if (!currentComponents.includes(component)) {
      setCurrentComponents((prevComponents) => [...prevComponents, component]);
    }
  };

  const removeComponent = (component) => {
    if (currentComponents.includes(component)) {
      setCurrentComponents((prevComponents) =>
        prevComponents.filter((comp) => comp !== component)
      );
      context.snack.show(friendlyName(component) + " removed");
    }
  };

  const renderComponent = (component) => {
    switch (component) {
      case "chat_advanced_params":
        return (
          <AdvancedChatParams
            id={params.id}
            onRemove={() => removeComponent(component)}
          />
        );
      case "thumbnail":
        return (
          <PromptThumbnail
            id={params.id}
            onRemove={() => removeComponent(component)}
          />
        );
      default:
        return null;
    }
  };

  const friendlyName = (name) => {
    return name
      .replace(/_/g, " ")
      .replace(/\b\w/g, (l) => l.toUpperCase())
      .replace("Ai", "AI");
  };

  return (
    <>
      <Header>
        {isEditMode ? (
          <button
            className="red-btn delete-btn"
            onClick={() => deletePrompt(params.id)}
          >
            DELETE
          </button>
        ) : null}
      </Header>
      <Grid cols={2} id="promptDesigner">
        <OrganizationCard title="Ai Creator">
          <ExpandableCard title="Setup" expanded={true}>
            <form method="post">
              <div className="form-group">
                <label>
                  Is active?
                  <input
                    name="active"
                    type="checkbox"
                    checked={formData.active || true}
                    onChange={handleChange}
                  />
                </label>

                <label>Choose the type of Ai:</label>
                <select
                  required
                  name="type"
                  value={formData.type || ""}
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    Type of Ai
                  </option>
                  <option value="tool">Tool</option>
                  <option value="expert">Expert</option>
                  <option value="art">Art</option>
                </select>

                <select
                  required
                  name="model"
                  value={formData.model || ""}
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    Ai Model
                  </option>

                  <option value="gpt-4">Chat GPT4</option>
                  <option value="gpt-3.5-turbo">Chat GPT3 Turbo</option>
                  <option value="Image">Image</option>
                </select>
              </div>

              <div
                className="form-group"
                style={{ display: formData.model !== "" ? "block" : "none" }}
              >
                <input
                  name="title"
                  placeholder="Title"
                  required
                  value={formData.title || ""}
                  onChange={handleChange}
                />

                <textarea
                  name="description"
                  required
                  placeholder="Description"
                  value={formData.description || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <ExpandableSection title="Topics" expanded={false}>
                  <div className="checkboxes">
                    {topics.map((data, index) => (
                      <legend key={index}>
                        <input
                          type="checkbox"
                          name="topic"
                          value={data.value}
                          onChange={handleTopicChange}
                          checked={topic.includes(data.value)}
                          required={topic.length === 0}
                        />
                        {data.label}
                      </legend>
                    ))}
                  </div>
                </ExpandableSection>
                <ExpandableSection title="Users" expanded={false}>
                  <div className="checkboxes">
                    {usertypes.map((data, index) => (
                      <legend key={index}>
                        <input
                          type="checkbox"
                          name="usertype"
                          value={data.type}
                          onChange={handleUsertypeChange}
                          checked={usertype.includes(data.type)}
                          required={usertype.length === 0}
                        />
                        {data.type}
                      </legend>
                    ))}
                  </div>
                </ExpandableSection>
                <div
                  style={{
                    display:
                      formData.model !== "gpt-4" &&
                      formData.model !== "gpt-3.5-turbo"
                        ? "none"
                        : "block",
                  }}
                >
                  <textarea
                    name="instructions"
                    value={formData.instructions || ""}
                    placeholder="Instructions for the AI to follow"
                    onChange={handleChange}
                    required={
                      formData.model === "gpt-4" ||
                      formData.model === "gpt-3.5-turbo"
                    }
                  />

                  <textarea
                    name="greeting"
                    placeholder="Greeting for the user"
                    value={formData.greeting || ""}
                    onChange={handleChange}
                  />

                  {messages.map((data, index) => {
                    return (
                      <div key={index}>
                        {messages.length > 0 ? (
                          <Button
                            icon="minus"
                            color="red"
                            small={true}
                            className="removeMessage"
                            action={() => removeMessage(index)}
                          />
                        ) : (
                          ""
                        )}
                        <label>{data.role} message</label>

                        <textarea
                          value={data.content || ""}
                          name="message"
                          onChange={(event) =>
                            handleMessageChange(index, event)
                          }
                          required={false}
                        />
                      </div>
                    );
                  })}

                  <button type="button" onClick={addMessage}>
                    Add Training Message
                  </button>
                </div>

                {/* end chat only */}
              </div>

              <div
                style={{
                  display:
                    formData.model === "Completion" ||
                    formData.model === "Image"
                      ? "block"
                      : "none",
                }}
              >
                <label>Instructions:</label>
                <textarea
                  name="instructions"
                  required={
                    formData.model === "Completion" ||
                    formData.model === "Image"
                  }
                  onChange={handleChange}
                  value={formData.prompt || ""}
                />
              </div>
              {/* end completion or image  */}
              <div
                style={{
                  display: formData.model !== "Completion" ? "none" : "block",
                }}
              >
                <label>Prefix:</label>
                <textarea name="prefix" onChange={handleChange} />
                <label>Suffix</label>
                <textarea name="suffix" onChange={handleChange} />
              </div>
              {/* end completion only */}

              <label>Version Number:</label>
              <input
                name="version"
                required
                value={formData.version || "1.0"}
                onChange={handleChange}
              />
              <Button
                action={handleSubmit}
                text={isEditMode ? "Update prompt" : "Create prompt"}
                big="true"
                color="green"
              />
            </form>
          </ExpandableCard>
          {/* Area to load components */}
          <div>
            {currentComponents.map((component, index) => (
              <div key={component}>
                <ExpandableCard title={friendlyName(component)}>
                  {renderComponent(component)}
                </ExpandableCard>
              </div>
            ))}
          </div>
        </OrganizationCard>
        <OrganizationCard title="Components">
          {isEditMode ? (
            <>
              <Button
                text="Advanced Chat Params"
                icon="plus"
                color="#d95565"
                size={13}
                action={() => addComponent("chat_advanced_params")}
              />
              <Button
                text="Thumbnail"
                icon="plus"
                color="#d95565"
                size={13}
                action={() => addComponent("thumbnail")}
              />
            </>
          ) : null}
        </OrganizationCard>
      </Grid>
      <div id="snackbar"> </div>
    </>
  );
}

export default Designer;
