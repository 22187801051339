/*
 * Words page
 * View to allow the admin to magage the organization's banned words
 *
 */

import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";

import {
  OrganizationCard,
  ViewContext,
  Header,
  useAPI,
  Form,
} from "components/lib";

export function Words(props) {
  const params = useParams();
  const context = useContext(ViewContext);
  let isSchool = false;

  //State
  const [words, setWords] = useState();

  // get the organization settings
  let url = "/api/organization/settings/get";
  if (params.id) {
    // this is a school change the url
    // and set school to true
    url = "/api/organization/settings/" + params.id;
    isSchool = true;
  }
  // get organization
  const organization = useAPI(url);

  // change the url based on if this is a school or district
  let getWordsUrl = "/api/organization/district/settings/get/words";
  if (isSchool)
    getWordsUrl = "/api/organization/school/settings/get/words/" + params.id;

  // change the url based on if this is a school or district
  let setWordsUrl = "/api/organization/district/settings/set/words";
  if (isSchool)
    setWordsUrl = "/api/organization/school/settings/set/words/" + params.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get the prompts
        const response = await Axios.get(getWordsUrl);
        // Save the data to the state
        setWords(response.data.data || " ");
      } catch (error) {
        console.error(`Failed to fetch data: ${error}`);
      }
    };

    if (!organization.loading && organization?.data) {
      fetchData();
    }
  }, [organization]);

  return (
    <>
      <Header />

      <header className="content-header">
        <h1>
          Manage Words: <span>{organization?.data?.name}</span>
        </h1>
      </header>

      <OrganizationCard
        loading={organization.loading}
        title={isSchool ? "School Setting" : "District Setting"}
      >
        <p>
          The 'Blocked Words' feature allows you to have control over which
          words are prevented from being used to interact with your AI within
          your organization.
          {!isSchool &&
            " In addition, school administrators can further manage words for their schools."}
          {isSchool &&
            " In addition, district administrators have the ability to manage words for the entire district. If you're unable to use specific words, we recommend checking with your district administrator to confirm its availability for your school."}
          <br /> <br />
        </p>

        <OrganizationCard title={"Blocked Words"}>
          {words && (
            <Form
              buttonText="Save"
              url={setWordsUrl}
              method="POST"
              inputs={{
                words: {
                  label: "Seperate words with a comma",
                  type: "textarea",
                  value: words,
                  errorMessage: "Please enter some words",
                },
                org_id: {
                  type: "hidden",
                  value: organization?.data?.id,
                },
              }}
              callback={(res) => {
                context.notification.show(
                  "Blocked words saved.",
                  "success",
                  true,
                  "toast",
                  "check"
                );
              }}
            />
          )}
        </OrganizationCard>
      </OrganizationCard>
    </>
  );
}
