/***
 *
 *   SOCIAL SIGN IN BUTTONS
 *   To sign up/in with Facebook, Google or Microsoft
 *
 **********/

import { useState } from "react";
import { Button, Link } from "components/lib";
import { ClassHelper, Grid } from "components/lib";
import settings from "settings";
import Style from "./social.module.scss";

export function SocialSignin(props) {
  const [loading, setLoading] = useState(
    props.network?.map((x) => {
      return { [x]: false };
    })
  );
  const serverURL = settings[process.env.REACT_APP_ENV].server_url;

  // construct query string
  let qs = "";
  if (props.invite) qs = `?invite=${props.invite}`;
  if (props.signup) qs = "?signup=1";

  return (
    <div className={Style.signinButtons}>
      {props.showOr && <span className={Style.or}>or</span>}

      {props.network?.map((n) => {
        const css = ClassHelper(Style, { [n]: true, signinButton: true });

        return (
          <Link
            key={n}
            loading={loading[n]}
            action={() => setLoading({ [n]: true })}
            url={`${serverURL}/auth/${n}${qs}`}
            text={`${n.charAt(0).toUpperCase() + n.slice(1)}`}
            className={css}
            color="lightblue"
          />
        );
      })}
    </div>
  );
}
