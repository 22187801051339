/***
 *
 *   ORGANIZATION Page
 *   view all organizations that belong to an acccount
 *
 **********/

import { useParams } from "react-router-dom";

import { useContext, useState, useEffect } from "react";
import axios from "axios";

import {
  useNavigate,
  OrganizationCard,
  Header,
  Grid,
  Table,
  Animate,
  OrganizationTable,
  useAPI,
  AuthContext,
  Button,
  ViewContext,
  usePermissions,
  Event,
} from "components/lib";

export function District(props) {
  const navigate = useNavigate();

  const params = useParams();
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const permissions = usePermissions();
  const data = useAPI("/api/account/users/permission/district_admin");
  console.log("data", data);
  const [users, setUsers] = useState([]);

  //state
  const [rosterStatus, setRosterStatus] = useState([]);

  const account_id = authContext.user.account_id;

  // get the current roster settings
  let rosterSettings = useAPI("/api/account/settings");

  // get district
  const distrct = useAPI("/api/account/district/" + params.id) || [];

  // get schools
  // Initialize state with the fetched data
  const [schools, setSchools] = useState([]);

  const fetchedSchools = useAPI("/api/account/schools") || [];

  useEffect(() => {
    setSchools(fetchedSchools.data);
  }, [fetchedSchools]);

  //const schoolList = ["name", "contact_name", "city", "active"];
  const schoolList = ["name"];

  // get the user count
  const userCount = useAPI("/api/account/" + account_id + "/user/count") || [];

  useEffect(() => {
    // format the user list
    let list = [];

    if (data?.data?.users?.length) {
      list = data.data.users
        .filter((x) => x.permission === "district_admin")
        .map((x) => {
          return {
            id: x.id,
            avatar: x.avatar,
            name: x.name,
            email: x.email,
            date_created: x.date_created,
            permission: x.permission,
            status: x.verified ? "Verified" : "Registered",
          };
        });
    }
    if (data?.data?.invites?.length) {
      data.data.invites
        .filter((x) => x.permission === "district_admin")
        .forEach((x) => {
          list.push({
            id: x.id,
            name: "",
            email: x.email,
            date_created: x.date_sent,
            permission: x.permission || "user",
            status: "Invited",
          });
        });
    }

    setUsers(list);
  }, [data]);

  // attach the per row actions for invites
  if (users.length) {
    users.forEach((u) => {
      if (u.status === "Invited") {
        u.actions = {
          invite: resendInvite,
          delete: deleteInvite,
        };
      }
    });
  }

  function addSchool() {
    viewContext.modal.show(
      {
        title: "Create School",
        buttonText: "Save",
        url: "/api/organization/new",
        method: "POST",

        form: {
          account_id: {
            type: "hidden",
            value: account_id,
          },
          type: {
            label: "Type",
            type: "hidden",
            value: "school",
          },
          name: {
            label: "Name",
            type: "text",
            required: true,
          },
          active: {
            label: "Active",
            type: "switch",
            default: 1,
            required: true,
          },
        },
      },
      (res) => {
        if (res && res.data && res.data.data) {
          navigate(`/organization/school/${res.data.data.id}`);
        }
      }
    );
  }

  function addDistrictAdmin() {
    viewContext.modal.show(
      {
        title: "Add District Admin",
        form: {
          email: {
            label: "Email",
            type: "text",
            required: true,
          },

          permission: {
            label: "Permission",
            value: "district_admin",
            type: "hidden",
          },
        },
        buttonText: "Send Invite",
        text: "To invite more than one user, seperate the emails with a comma",
        url: "/api/invite",
        method: "POST",
      },
      (form, res) => {
        // add the invited user to the
        if (res && res.length) {
          const state = [...users];

          res.forEach((invite) => {
            if (!state.find((x) => x.id === invite.id)) {
              state.push({
                id: invite.id,
                name: "",
                email: invite.email,
                date_created: invite.date_sent,
                permission: invite.permission || "teacher",
                status: "Invited",
                actions: {
                  invite: resendInvite,
                  delete: deleteInvite,
                },
              });
            }
          });

          Event("invited_user");
          setUsers(state);
        }
      }
    );
  }

  function editUser(data, callback) {
    viewContext.modal.show(
      {
        title: "Update District Admin",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          name: {
            label: "Name",
            type: "text",
            required: true,
            value: data.name,
            errorMessage: "Please enter a name",
          },
          email: {
            label: "Email",
            type: "email",
            value: data.email,
            required: true,
          },
          permission: {
            label: "Permission",
            type: data.permission === "district_admin" ? null : "select",
            options: permissions?.data?.list?.filter(
              (x) =>
                x.value !== "district_admin" &&
                x.value !== "school_admin" &&
                x.value !== "teacher"
            ),
            default: data.permission,
          },
        },
        buttonText: "Save",
        url: "/api/user",
        method: "PATCH",
      },
      (res) => {
        viewContext.notification.show(
          data.name + " was updated",
          "success",
          true
        );
        callback(res);
      }
    );
  }

  function deleteUser(data, callback) {
    viewContext.modal.show(
      {
        title: "Delete District Admin",
        form: {},
        buttonText: "Delete Admin",
        text: `Are you sure you want to delete ${data.name}?`,
        url: `/api/user/${data.id}`,
        method: "DELETE",
        destructive: true,
      },
      () => {
        viewContext.notification.show(
          data.name + " was deleted",
          "success",
          true
        );
        callback();
      }
    );
  }

  function deleteInvite(data, callback) {
    viewContext.modal.show(
      {
        title: "Delete Invite",
        form: {},
        buttonText: "Delete Invite",
        text: `Are you sure you want to delete the invite for ${data.email}?`,
        url: `/api/invite/${data.id}`,
        method: "DELETE",
        destructive: true,
      },
      () => {
        viewContext.notification.show(
          `${data.email}'s invite was deleted`,
          "success",
          true
        );
        callback();
      }
    );
  }

  async function resendInvite(data) {
    try {
      await axios({
        url: "/api/invite",
        method: "post",
        data: { email: data.email },
      });

      viewContext.notification.show(
        `Invite re-sent to ${data.email}`,
        "success",
        true
      );
    } catch (err) {
      viewContext.handleError(err);
    }
  }

  return (
    <>
      <Header />

      <header className="content-header">
        <h1>
          District: <span>{distrct.data?.name}</span>
        </h1>
        <p>
          The top level organization of your account encompasses the school
          district, along with all the schools and users within it. These
          entities can be created manually or if rostering, automatically during
          the rostering process.
        </p>

        <Button
          url={"/organization/content/district/" + params.id}
          text="Content"
          icon="check-square"
        />
        <Button
          url={"/organization/words/district/" + params.id}
          text="Blocked Words"
          icon="slash"
        />
        {!distrct.data?.contact_name ? null : (
          <>
            <b>Primary Contact:</b>
            <br />
            <b>Name: </b> {distrct.data?.contact_name}
            <br />
            <b>Email: </b> {distrct.data?.contact_email}
            <br />
            {distrct.data?.contact_title
              ? <b>Title: </b> + (distrct.data?.contact_title).toUpperCase()
              : ""}
            <br />
          </>
        )}
      </header>

      <Animate>
        <OrganizationCard loading={distrct.loading} title="About">
          {!distrct.data?.location_state ? null : (
            <div>
              <b>Location:</b>
              <br />
              State: {distrct.data?.location_state}
              <br />
              City: {distrct.data?.location_city}
              <br />
            </div>
          )}

          <Grid cols={2}>
            <OrganizationCard loading={userCount.loading} title="Stats">
              <b> Schools:</b> {schools?.length}
              <br />
              District Admins: {userCount.data?.district_admin_count}
              <br />
              School Admins: {userCount.data?.school_admin_count}
              <br />
              Teachers: {userCount.data?.teacher_count}
              <br />
              Students:{userCount.data?.student_count}
              <br />
            </OrganizationCard>

            {}
            <OrganizationCard title="Roster">
              <cite className="badge">
                {distrct.data?.is_roster ? "Rostered" : "Not Rostered"}
              </cite>
              {distrct.data?.is_roster ? (
                <>
                  <div>
                    <b> Roster Service:</b> {rosterSettings.data?.roster_source}
                  </div>
                  <div>
                    <b>Auto-sync: </b>
                    {rosterSettings.data?.roster_autosync === 1
                      ? "true"
                      : "false"}
                    <br />
                    <b>Last Update: </b>
                    {new Date(
                      rosterSettings.data?.roster_last_sync
                    ).toLocaleDateString() ?? "never"}
                    <br />
                  </div>
                  <div>{rosterStatus} </div>
                </>
              ) : (
                <div>
                  Contact support to enable rostering for your district.
                </div>
              )}
            </OrganizationCard>
          </Grid>
        </OrganizationCard>
        <br />
        <Button
          action={addDistrictAdmin}
          text="Add District Admin"
          icon="plus"
          outline
          color="blue"
        />

        <OrganizationCard loading={data.loading} title="District Admins">
          <OrganizationTable
            data={users}
            loading={data.loading}
            show={["email", "name", "status"]}
            badge={{
              col: "status",
              color: "blue",
              condition: [
                { value: "verified", color: "green" },
                { value: "registered", color: "blue" },
                { value: "invited", color: "orange" },
              ],
            }}
            actions={{
              edit: editUser,
              delete: deleteUser,
              email: true,
            }}
          />
        </OrganizationCard>

        <br />
        <Button
          action={addSchool}
          text="Create New School"
          icon="plus"
          outline
          color="blue"
        />
        <OrganizationCard title={"Schools " + schools?.length}>
          <OrganizationTable
            search
            className="single_action"
            data={schools}
            show={schoolList}
            link={{
              url: "/organization/school/",
              id: "id",
            }}
          />
        </OrganizationCard>
      </Animate>
    </>
  );
}
