import { History } from "views/app/history";
import { Help } from "views/dashboard/help";
import { OnboardingView } from "views/onboarding/onboarding";
import { Account } from "views/account";

const Routes = [
  {
    path: "/welcome",
    view: OnboardingView,
    layout: "onboarding",
    permission: "user",
    title: "Welcome",
  },
  {
    path: "/help",
    view: Help,
    layout: "app",
    permission: "user",
    title: "Get Help",
  },
  {
    path: "/history",
    view: History,
    layout: "app",
    permission: "user",
    title: "History",
  },
  {
    path: "/history/:prompt_id",
    view: History,
    layout: "app",
    permission: "user",
    title: "Prompt History",
  },

  {
    path: "/dashboard",
    view: Account,
    layout: "app",
    permission: "user",
    title: "Account",
  },
];

export default Routes;
