import { Icon, ClassHelper } from "components/lib";
import Style from "./chatMessage.module.scss";

export function ChatMessage(props) {
  const chatStyle = ClassHelper(Style, props);

  return (
    <div className={chatStyle}>
      <div className={Style[props.row]}>
        {/* <Image source={props.image} alt={props.author} className="avatar" /> 
                <span className={Style.circle}></span>
      */}
        <Icon image={props.icon} size={18} className={Style.icon} />

        <p className={Style.chatMessageText}>{props.text}</p>
      </div>
    </div>
  );
}
