/***
 *
 *   SHARE
 *   Display the share modal
 *
 *   PROPS
 *   title: (optional)
 *   text: message to the user (optional)
 *   form: a form object (optional: see form docs for more information)
 *   url: destination to send the form
 *   method: HTTP post type
 *   buttonText – text for the confirm button
 *
 **********/

import { Button, Card } from "components/lib";
import { CSSTransition } from "react-transition-group";
import { QRCode } from "react-qr-code";
import settings from "settings";
import "./share.scss";

export function Share(props) {
  const clientURL = settings[process.env.REACT_APP_ENV].client_url;

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(() => {});
    showSnackbarMessage("Copied to clipboard");
  }

  function showSnackbarMessage(message) {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");
    x.innerHTML = message;

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  }

  return (
    <CSSTransition in appear timeout={0} classNames="share">
      <div className="share" onClick={() => props.hide()}>
        <div className="share-content">
          <Card title={props.title}>
            <QRCode
              size={128}
              style={{ height: "auto", maxWidth: "50%", width: "50%" }}
              value={clientURL + props.url}
              viewBox={`0 0 256 256`}
            />
            <p>{clientURL + props.url}</p>
            <Button
              action={() => copyToClipboard(clientURL + props.url)}
              text="Copy to Clipboard"
            />
          </Card>
        </div>
        <div id="snackbar"></div>
      </div>
    </CSSTransition>
  );
}
