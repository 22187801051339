/***
 *
 *   ACCOUNT
 *   Index page for account functions
 *
 **********/

import React, { useContext, useState, useEffect } from "react";

import {
  AuthContext,
  Animate,
  Button,
  OrganizationCard,
  useAPI,
  Loader,
} from "components/lib";

import Axios from "axios";

export function Account(props) {
  const context = useContext(AuthContext);
  const userContext = context.user;
  const userData = useAPI("/api/user");
  console.log("user", userData);

  const [state, setState] = useState([{ loading: true }]);

  function friendlyPermissionName(str) {
    if (!str) return;
    // Split the string into an array on underscore
    let strArr = str.split("_");

    // Map over the array and capitalize each word
    let capitalizedArr = strArr.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // join the words back into a single string with no underscore
    let result = capitalizedArr.join(" ");

    return result;
  }

  return (
    <>
      <header className="content-header">
        <h1>
          <span> Account</span>
        </h1>
      </header>
      <Animate>
        <OrganizationCard className="account" title="Account Management">
          {context.permission?.account_admin && (
            <cite className="badge">
              {friendlyPermissionName(userContext?.permission)}
            </cite>
          )}
          <h4>
            Hello {userData.data?.name_first} {userData.data?.name_last}
          </h4>
          <p>
            Account management provides you with the ability to update your
            personal information, such as your name and email address.
            Additionally, you can manage your password and security settings, as
            well as change your payment information if needed.
          </p>

          <Button
            url="/account/profile"
            text={"Profile"}
            className={Style.link}
            icon="user"
          />

          <Button
            url="/account/password"
            text={"Password "}
            className={Style.link}
            icon="lock"
          />

          <Button
            url="/account/2fa"
            text={"Security 2FA"}
            className={Style.link}
            icon="shield"
          />
          {context.permission?.account_admin && (
            <Button
              url="/account/billing"
              text="billing"
              className={Style.link}
              icon="credit-card"
            />
          )}
          {context.permission?.account_admin && (
            <Button
              url="/account/users"
              text={"Admins"}
              className={Style.link}
              icon="user"
            />
          )}
        </OrganizationCard>
      </Animate>
    </>
  );
}

const Style = {
  subtitle: "font-bold mt-4",
  link: "text-blue-500 font-semibold mt-3 inline-block",
};
